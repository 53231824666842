import React from "react";

import { Stack, Typography } from "@mui/material";

interface IProps {
  isEligible: boolean;
}

export const Heading: React.FC<IProps> = ({ isEligible }) => {
  return (
    <Stack
      gap={2}
      className="galaxy-color"
      style={{
        width: "100%",
        fontSize: "48px",
        justifyContent: "center",
      }}
    >
      <Stack gap={2}>
        <Typography
          className="ab"
          style={{
            fontSize: "48px",
            lineHeight: "0.9",
          }}
          textAlign="center"
        >
          W-Coin
        </Typography>
        <Typography
          className="ab"
          style={{
            fontSize: "48px",
            lineHeight: "0.7",
          }}
          textAlign="center"
        >
          Premarket
        </Typography>
      </Stack>
      <Stack alignItems="center" style={{ fontSize: "14px" }}>
        {isEligible ? (
          <Typography color="#D7D7D7">
            Congratulations! Vouchers will appear in your wallet
          </Typography>
        ) : (
          <>
            <Typography color="#D7D7D7">
              Trade W-Coin using vouchers,
            </Typography>
            <Typography color="#D7D7D7" style={{ fontSize: "14px" }}>
              before token is issued.
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};
