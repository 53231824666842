import React from "react";

import {
  useHapticFeedback,
  useWebApp,
} from "@vkruglikov/react-telegram-web-app";

import { TTelegramGroupVerify } from "../../contexts/types";
import { useOpenTelegramLink } from "../../hooks";
import {
  useUpdateGetCheckJetTonTaskExternalApiMutation,
  useUpdateGetCheckTrumpEmpireTaskExternalApiMutation,
  useUpdateGetNothingApiMutation,
  useUpdateGetRokoApiMutation,
  useUpdateGetVerifyBurgerBlastApiMutation,
  useUpdateGetVerifyChainGptApiMutation,
  useVerifyTaskMutation,
} from "../../feature/users";

import { ITask } from "../type";
import { Spinner } from "../../spinner";
import axios from "axios";

interface IProps {
  currentTask: ITask;
  onClaim?: (rawResponse?: any) => void;
  onTelegramGroupVerify?: TTelegramGroupVerify;
}

export const ExternalVerifyButton: React.FC<IProps> = ({
  currentTask,
  onClaim,
}) => {
  const webApp = useWebApp();

  const { onOpenTelegramLink } = useOpenTelegramLink();

  const [checkJetTonTaskAsync, checkJetTonTaskAsyncState] =
    useUpdateGetCheckJetTonTaskExternalApiMutation();
  const [checkTrumpsImpireTaskAsync, checkTrumpsImpireTaskAsyncState] =
    useUpdateGetCheckTrumpEmpireTaskExternalApiMutation();
  const [burgerBlast, burgerBlastState] =
    useUpdateGetVerifyBurgerBlastApiMutation();
  const [nothing, nothingState] = useUpdateGetNothingApiMutation();
  const [roko, rokoState] = useUpdateGetRokoApiMutation();

  const [chainGpt, chainGptState] = useUpdateGetVerifyChainGptApiMutation();

  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [isTryVerified, setIsTryVerified] = React.useState<
    boolean | undefined
  >();
  const [impactOccurred] = useHapticFeedback();

  const [rawResponse, setRawResponce] = React.useState<any>();

  const [verifyTask, verifyTaskState] = useVerifyTaskMutation();
  // @ts-ignore
  const telegramUserId =
    // @ts-ignore
    window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;

  const handleVerify = () => {
    if (currentTask.type === "jetTonDeposit_2") {
      checkJetTonTaskAsync()
        .then((res) => {
          // @ts-ignore
          if (res?.data?.rawResponse?.visited) {
            setIsVerified(true);
            setIsTryVerified(undefined);
          } else {
            setIsVerified(false);
            setIsTryVerified(true);
          }
        })
        .catch((e) => console.log(e, "error jetTonDeposit_2"));
    } else if (currentTask.type === "BOINK_APP_SPIN") {
      axios
        .get("https://boom.astronomica.io/api/partner/check", {
          params: {
            partnerName: "WCoinPartner",
            userTelegramId: telegramUserId,
            apiKey:
              "2840a67a3e9520de554ee2fffb09c515374ff1be8876c8a61e53f5aa7fee3da6",
            check10Spins: 1,
          },
        })
        .then((response) => {
          if (
            response.data.complete &&
            response.data.isMember &&
            response.data.isSpinnedSlutMachine10Times
          ) {
            setIsVerified(true);
            setIsTryVerified(undefined);
          } else {
            setIsVerified(false);
            setIsTryVerified(true);
          }
        })
        .catch((error) => {
          setIsVerified(false);
          setIsTryVerified(true);
        });
    } else if (currentTask.type === "trumpsEmpire") {
      checkTrumpsImpireTaskAsync()
        .unwrap()
        .then((res) => {
          // @ts-ignore
          if (res?.data?.status == "success") {
            setIsVerified(true);
            setIsTryVerified(undefined);
          } else {
            setIsVerified(false);
            setIsTryVerified(true);
          }
        });
    } else if (currentTask.type === "play_burger_2") {
      burgerBlast()
        .unwrap()
        .then((res) => {
          // @ts-ignore
          if (res?.data?.data?.done) {
            setIsVerified(true);
            setIsTryVerified(undefined);
          } else {
            setIsVerified(false);
            setIsTryVerified(true);
          }
        })
        .catch((error) => {
          setIsVerified(false);
          setIsTryVerified(true);
        });
    } else if (currentTask.type === "ai_revolution_2") {
      chainGpt()
        .unwrap()
        .then((res) => {
          // @ts-ignore
          const resData = res?.data?.visitors?.filteredParticipants;

          // @ts-ignore
          if (resData && resData[0] && resData[0]?.generated) {
            setIsVerified(true);
            setIsTryVerified(undefined);
          } else {
            setIsVerified(false);
            setIsTryVerified(true);
          }
        })
        .catch((error) => {
          setIsVerified(false);
          setIsTryVerified(true);
        });
    } else if (currentTask.type === "nothing") {
      nothing()
        .unwrap()
        .then((res) => {
          // @ts-ignore
          if (res?.status === "success") {
            setIsVerified(true);
            setIsTryVerified(undefined);
          } else {
            setIsVerified(false);
            setIsTryVerified(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsVerified(false);
          setIsTryVerified(true);
        });
    } else if (currentTask.type === "roko") {
      roko()
        .unwrap()
        .then((res) => {
          // @ts-ignore
          if (res?.data?.status) {
            setIsVerified(true);
            setIsTryVerified(undefined);
          } else {
            setIsVerified(false);
            setIsTryVerified(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsVerified(false);
          setIsTryVerified(true);
        });
    } else if (telegramUserId && currentTask.verifyExternalTaskName) {
      try {
        verifyTask({
          telegramUser: String(telegramUserId),
          taskName: currentTask.verifyExternalTaskName,
        })
          .unwrap()
          .then((res) => {
            if (res?.isFinished) {
              setIsVerified(true);
              setIsTryVerified(undefined);
            } else {
              setIsVerified(false);
              setIsTryVerified(true);
            }
          })
          .catch((error) => {
            console.error(error);
            setIsVerified(false);
            setIsTryVerified(true);
          });

        impactOccurred("heavy");
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleTryVerifiedContent = () => {
    if (typeof isTryVerified === "undefined") {
      return null;
    } else if (isTryVerified === true) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            top: "-36%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            color: "red",
          }}
        >
          <p>Task not completed.</p>
          <p>Try again</p>
        </div>
      );
    } else {
      return null;
    }
  };

  const onOpenExternalTelegramEntity = (link: string | null) => {
    if (currentTask.type === "ai_revolution_2") {
      webApp.openLink(link);
    } else {
      webApp.openTelegramLink(link);
    }
  };

  const handleOpenLink = React.useCallback(() => {
    return currentTask?.is_done
      ? onOpenTelegramLink()
      : onOpenExternalTelegramEntity(currentTask.link);
  }, [
    currentTask?.is_done,
    currentTask.link,
    onOpenExternalTelegramEntity,
    onOpenTelegramLink,
  ]);
  const isLoading =
    verifyTaskState.isLoading ||
    checkJetTonTaskAsyncState.isLoading ||
    checkTrumpsImpireTaskAsyncState.isLoading ||
    burgerBlastState.isLoading ||
    chainGptState.isLoading ||
    nothingState.isLoading ||
    rokoState.isLoading;

  return (
    <div
      className="task-modal-button-wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        position: "relative",
      }}
    >
      <Spinner isLoading={isLoading} />
      {handleTryVerifiedContent()}
      {!currentTask.is_done && (
        <button
          onClick={isVerified ? () => onClaim?.(rawResponse) : handleVerify}
          className={`app-modal-content-button ${
            currentTask.is_done ? "btn-disabled" : ""
          }`}
        >
          {isVerified ? "Claim" : "Verify"}
        </button>
      )}

      {!currentTask.is_done && (
        <div className="task-modal-button-wrapper">
          <button
            onClick={handleOpenLink}
            className={`app-modal-content-button`}
          >
            {currentTask?.btnLabel}
          </button>
        </div>
      )}
    </div>
  );
};
