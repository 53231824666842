import React from "react";

import { Stack } from "@mui/material";
import { BackButton } from "@vkruglikov/react-telegram-web-app";

import { TemplatePages } from "../template-pages";

import { usePageNftApi } from "./hooks";
import { Header } from "./header";
import { Heading } from "./heading";
import { Content } from "./content";
import { ROUTES } from "../routers";
import { ConditionsModal } from "./conditions-modal";
import { CheckModal } from "./check-modal";
import { InfoModal } from "./info-modal";

const bgIcon = require("./img/bg.jpg") as string;

export const PageNewNftAirdrop: React.FC = () => {
  const {
    isLoading,
    CONDITIONS_TASKS,
    handleConnectWallet,
    handleDisconnect,
    address,
    databaseWalletAddress,
    canShowConnectedBtn,
    nftAirdropData,
    isFetching,
    isEligible,
    showConditionsModal,
    handleShowConditionsModal,
    showInfoModal,
    handleInfoModal,
    handleGasPaymentConnect,
    failedTransaction,
    handleSentGasTransaction,
    gasAddress,
    checkModalOpen,
    handleCheckModalOpen,
    isSentStatus,
  } = usePageNftApi();

  return (
    <TemplatePages isLoading={isLoading} isFetching={isFetching} bg={bgIcon}>
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      <CheckModal
        open={checkModalOpen && !isSentStatus}
        close={() => handleCheckModalOpen(false)}
      />
      {(showConditionsModal || showInfoModal) && (
        <div
          className={`overlay app-modal-root ${
            showConditionsModal || showInfoModal ? "blur" : ""
          }`}
          style={{ zIndex: "12" }}
        ></div>
      )}
      <ConditionsModal
        show={showConditionsModal}
        onClose={() => handleShowConditionsModal(false)}
      />
      <InfoModal open={showInfoModal} close={() => handleInfoModal(false)} />
      <Header onInfoModalOpen={() => handleInfoModal(true)} />
      <Stack
        flexDirection="column"
        textAlign="center"
        height="calc(100% - 60px)"
        width="100%"
        justifyContent="flex-start"
        overflow="auto"
        style={{
          padding: "40px 24px 10px 24px",
        }}
      >
        <Heading
          isEligible={isEligible}
          isSentStatus={isSentStatus}
          databaseWalletAddress={databaseWalletAddress}
        />
        <Content
          isEligible={isEligible}
          list={CONDITIONS_TASKS}
          handleConnectWallet={handleConnectWallet}
          handleDisconnect={handleDisconnect}
          address={address}
          databaseWalletAddress={databaseWalletAddress}
          canShowConnectedBtn={canShowConnectedBtn}
          nftAirdropData={nftAirdropData}
          handleShowConditionsModal={handleShowConditionsModal}
          handleGasPaymentConnect={handleGasPaymentConnect}
          failedTransaction={failedTransaction}
          handleSentGasTransaction={handleSentGasTransaction}
          gasAddress={gasAddress}
          handleCheckModalOpen={handleCheckModalOpen}
          isSentStatus={isSentStatus}
        />
      </Stack>
    </TemplatePages>
  );
};
