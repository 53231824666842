import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import { UserContext } from "../contexts/user-context";

import { TConditionsType } from "./models";
import { CHAIN, SendTransactionRequest, useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useGetNftAirdropStatusQuery, useUpdateMintNftAirdropMutation, useUpdateNftAirdropWalletAddressMutation } from "../feature/users";
import { Address, beginCell, Cell, toNano } from "@ton/core";
import { IAirdropConditions } from "../feature/users/models";
import { LottieAnimationContext } from "../contexts/lottie-animation-context";
import { TAppUser } from "../contexts/types";

const useHandleGasPaumentApi = (user: TAppUser) => {
    const [updateGas, updateGasState] = useUpdateMintNftAirdropMutation()
        const { setIsVisible } = React.useContext(LottieAnimationContext);
    
        // Wallets connections
        const [tonConnectUI, setOptions] = useTonConnectUI();
        const wallet = useTonWallet();
        const address = wallet?.account?.address;
        const [impactOccurred] = useHapticFeedback();

        const [isProcessing, setIsProcessing] = React.useState(false);
        const databaseUserId = user?.claim_state?.id;
    
        // @ts-ignore
      const notAcceptedWallet = wallet?.name === "HOT";
      // @ts-ignore
      const isNetNotAccepted = React.useMemo(
        () => wallet?.account?.chain === CHAIN.TESTNET || notAcceptedWallet,
        [notAcceptedWallet, wallet?.account?.chain]
      );
    
      const [failedTransaction, setFailedTransaction] = React.useState(false);
    
    
      const handleSentTransaction = async () => {
        setFailedTransaction(false);
    
        if (isNetNotAccepted) {
            impactOccurred('heavy');
            await tonConnectUI.disconnect();
        } else {
        if (!wallet || !wallet.account?.address) {
          console.error("Wallet not connected");
          return;
        }
    
        if (address) {
          const transaction: SendTransactionRequest = {
            validUntil: Math.floor(Date.now() / 1000) + 3600,
            messages: [
              {
                address: "UQCf9teAoiGQeiHv7VGSru6HCw3BXhBXP7MMsRMU7jz6hxB1",
                amount: toNano(0.025).toString(),
                payload: beginCell()
                .storeUint(0, 32)
                // @ts-ignore
                .storeStringTail(`userId: ${databaseUserId}`)
                .endCell().toBoc().toString("base64"),
              },
            ],
          };
      
          try {
            const res = await tonConnectUI.sendTransaction(transaction);
          
            if (res?.boc) {
              const cell = Cell.fromBase64(res.boc);
              const buffer = cell.hash();
              const hashHex = buffer.toString("hex");
          
              if (hashHex && databaseUserId) {
                setIsProcessing(true);    
                updateGas({
                  transaction: hashHex,
                }).unwrap().then((res) => {
                  // @ts-ignore
                  if (res.status === 'success') {
                    setIsVisible?.(true);
                    setFailedTransaction(false);
      
                    const timeoutId = setTimeout(() => {
                      setIsVisible?.(false);
                    }, 4000);
                    setIsProcessing(false);

                  } else {
                    setIsProcessing(false);
                    setFailedTransaction(true);
                  }
                }).catch(() => {
                    setIsProcessing(false);
                    setFailedTransaction(true);
                });
              } 
            }
          } catch (e) {
            setIsProcessing(false);
            console.error(e, 'Transaction error');
          }
        }
        }
      };


      return {
        failedTransaction,
        handleSentGasTransaction: handleSentTransaction,
        isUpdateGasLoading: updateGasState.isLoading || isProcessing,
      }
}

export const usePageNftApi = () => {
    const [paymentType, setPaymentType] = React.useState<'GAS_FEE' | 'NFT_ADDRESS' | null>(null)
    const [impactOccurred] = useHapticFeedback();

    const [showConditionsModal, setShowConditionsModal] = React.useState<boolean>(false);
    const handleShowConditionsModal = (value: boolean) => {
        setShowConditionsModal(value);
    }

    const [showInfoModal, setInfoModal] = React.useState<boolean>(false);
    const handleInfoModal = (value: boolean) => {
      setInfoModal(value);
    }

    const { user, isLoading, setClaimState } =
    React.useContext(UserContext);

    const {data, isLoading: airdropIsLoading, isSuccess} = useGetNftAirdropStatusQuery();
    const [updateNftAirdropWalletAddress, updateNftAirdropWalletAddressState] = useUpdateNftAirdropWalletAddressMutation();
    const [tonConnectUI] = useTonConnectUI();

    const parsedTasks = React.useMemo(() => {
        if (data?.data?.conditions) {
            const parsed = JSON.parse(data.data.conditions) as IAirdropConditions;

            return {
                connect_wallet: parsed?.connect_wallet || false,
                active_mates: parsed?.active_mates || false,
                completed_tasks: parsed?.completed_tasks || false,
                completed_campaigns: parsed?.completed_campaigns || false,
                keys: parsed?.keys || false,
                wai: parsed?.wai || false,
                completed_stakes:parsed?.completed_stakes || false,
            }
        }

        return {
            connect_wallet: false,
            active_mates: false,
            completed_tasks: false,
            completed_campaigns: false,
            keys: false,
            wai: false,
            completed_stakes: false,
        }

    }, [data, isLoading, isSuccess])

    const [canUpdateAddress, setCanUpdateAddress] = React.useState<boolean>(false);

    const databaseWalletAddress = data?.data?.wallet_address;
    
    const isWalletConnected = tonConnectUI.connected;

    const canShowConnectedBtn = isSuccess && !databaseWalletAddress;

    const handleConnectWallet = () => { 
        setPaymentType('NFT_ADDRESS');
        tonConnectUI.openModal();
        setCanUpdateAddress(true);
    }

    const [gasAddress, setGasAddress] = React.useState<null | string>(null);
    const {failedTransaction, handleSentGasTransaction, isUpdateGasLoading} = useHandleGasPaumentApi(user)


    async function handleDisconnect() {
        setPaymentType(null)
        try {
          impactOccurred('heavy');
          await tonConnectUI.disconnect();
          handleConnectWallet();

        } catch (e) {
            handleConnectWallet();
          console.log("Error during disconnect:", e);
        }
    };

    const handleGasPaymentConnect = async () =>  {
        await handleDisconnect();
        setPaymentType('GAS_FEE');
        tonConnectUI.openModal();
        setCanUpdateAddress(true);
    }

    React.useEffect(() => {
        if (isWalletConnected && paymentType === 'GAS_FEE') {
            setGasAddress( wallet?.account?.address || null)
        }
    }, [isWalletConnected, paymentType])

    const wallet = useTonWallet();
    const address = wallet?.account?.address;

    React.useEffect(() => {
        if (isSuccess) {
            if (address && databaseWalletAddress && isSuccess) {
                const bounceableAddress = Address.parse(address).toString({
                    bounceable: false,
                  })
    
                if (bounceableAddress !== databaseWalletAddress) {
                    handleDisconnect();
                }
    
            } else if (address && !databaseWalletAddress) {
                handleDisconnect();
            }
        }
    }, [isSuccess])
    
    React.useEffect(() => {
        if (canUpdateAddress && address && paymentType === 'NFT_ADDRESS') {
            const bounceableAddress = Address.parse(address).toString({
                bounceable: false,
              })

            updateNftAirdropWalletAddress({
                wallet_address: bounceableAddress,
            }).then(() => {
                impactOccurred('heavy');
            })
        }

    }, [address, canUpdateAddress]);

    const CONDITIONS_TASKS: TConditionsType[] = [
        {
            text: 'Connected TON Wallet',
            isCompleted: parsedTasks.connect_wallet,
        },
        {
            text: '3 Active Mates',
            isCompleted: parsedTasks.active_mates,
            conditionsModal: true,
        },
        {
            text: '10 Completed Tasks',
            isCompleted: parsedTasks.completed_tasks,
        },
        {
            text: '2 Completed Campaign',
            isCompleted: parsedTasks.completed_campaigns,
        },
        {
            text: '1 W-Keys transaction',
            isCompleted: parsedTasks.keys,
        },
        {
            text: '100 WAI balance',
            isCompleted: parsedTasks.wai,
        },
        {
            text: '10 Completed Stakes',
            isCompleted: parsedTasks.completed_stakes,
        },
    ];

    const allConditionsCompleted = CONDITIONS_TASKS.every((i) => i.isCompleted);

    const [checkModalOpen, setCheckModalOpen] = React.useState(false);

    return {
        user,
        isLoading: isLoading || airdropIsLoading,
        isFetching: updateNftAirdropWalletAddressState.isLoading || isUpdateGasLoading,
        setClaimState,
        CONDITIONS_TASKS,
        allConditionsCompleted,
        handleConnectWallet,
        handleDisconnect,
        address,
        databaseWalletAddress,
        isWalletConnected,
        canShowConnectedBtn,
        nftAirdropData: data,
        isEligible: data?.data?.status === "PENDING" || data?.data?.status === "SENT",
        isSentStatus: data?.data?.status === "SENT",
        showConditionsModal,
        handleShowConditionsModal,
        showInfoModal,
        handleInfoModal,
        handleGasPaymentConnect,
        failedTransaction,
        handleSentGasTransaction,
        gasAddress,
        checkModalOpen,
        handleCheckModalOpen: (val: boolean) => setCheckModalOpen(val),
    }
}