import React from "react";

import { toast } from "react-toastify";
import { Buffer } from "buffer";
import millify from "millify";
import axios from "axios";

import { ESKins } from "./components/page-boosters/boosters/main-boosters/boosters-items/w-skins/types";
import { MIN_FORMAT_COIN_VALUE, PAY_PASS_MULTIPLIER, PAY_PASS_MULTIPLIER_LIGHT } from "./consts";
import { TSkin, TSkins } from "./components/feature/users/models";

// claim icons
const retroClaimIcon = require("./img/claim_retro.png") as string;
const defaultClaimIcon = require("./img/claim-coin.png") as string;
const defaultShurikenPawIcon = require("./img/claims_shuriken.png") as string;
const defaultCatPawIcon = require("./img/claim_catpaw.png") as string;
const defaultDotPawIcon = require("./img/claim_dot.png") as string;

const ethIcon = require("./img/eth3-min.png") as string;
const solIcon = require("./img/sol-min.png") as string;
const tonIcon = require("./img/ton-min.png") as string;
const trustIcon = require("./img/skins_trust.png") as string;
const runeIcon = require("./img/rune.png") as string;
const durovIcon = require("./img/free_durov_big.png") as string;
const catsIcon = require("./img/catsMainskin.png") as string;
const iceIcon = require("./img/ice_coin.png") as string;
const christmasIcon = require("./img/christmas_coin.png") as string;
const vinylicon = require("./img/vinyl.png") as string;

const runic = require("./img/runic.png") as string;
const polar = require("./img/polar.png") as string;
const diamondCore = require("./img/dimond-core.png") as string;

const space = require("./img/space.png") as string;
const gold = require("./img/Golden.png") as string;
const zombie = require("./img/Zombie.png") as string;

const claimSkinsIcons = {
  [ESKins.RETRO]: retroClaimIcon,
  [ESKins.DEFAULT]: defaultClaimIcon,
  [ESKins.SHURIKEN]: defaultShurikenPawIcon,
  [ESKins.CATIZEN]: defaultCatPawIcon,
  [ESKins.DOT]: defaultDotPawIcon,
  [ESKins.ETHEREUM]: ethIcon,
  [ESKins.SOLANA]: solIcon,
  [ESKins.TON]: tonIcon,
  [ESKins.TRUST]: trustIcon,
  [ESKins.RUNE]: runeIcon,
  [ESKins.FREE_DUROV]: durovIcon,
  [ESKins.CATS]: catsIcon,
  [ESKins.ICE]: iceIcon,
  [ESKins.CHRISTMAS]: christmasIcon,
  [ESKins.VINYL]: vinylicon,
  [ESKins.RUNIC]: runic,
  [ESKins.POLAR]: polar,
  [ESKins.DIAMOND_CORE]: diamondCore,
  [ESKins.SPACE]: space,
  [ESKins.GOLDEN]: gold,
  [ESKins.ZOMBIE]: zombie,

};

export const calculateTotal = (initialAmount: number, percent: number) => {
  return Math.floor(initialAmount * (1 + percent / 100));
};

export const formatValues = (value?: number | string) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getShareLink = () => {
  try {
    // @ts-ignore
    const telegramId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;

    const referralCode = Buffer?.from(telegramId?.toString()).toString(
      "base64"
    );
    const referralLink = `https://t.me/wcoin_tapbot/wcoin_app?startapp=${referralCode}`;

    const shareLink = `https://t.me/share/url?url=${referralLink}&text=${encodeURIComponent(
      "Join W-Coin and get bonuses for each invited friend!🎁"
    )}`;

    return {
      referralLink,
      shareLink,
    };
  } catch (error) {
    console.log(error);
  }
};
export const getClaimIcon = (skins?: TSkins | undefined) => {
  if (skins) {
    const selectedSkinType = Object.values(skins).find(
      (skin) => skin.selected
    )?.type;

    if (selectedSkinType) {
      return claimSkinsIcons[selectedSkinType as ESKins];
    }
  }

  return claimSkinsIcons[ESKins.DEFAULT];
};

export const getCSkinIcon = (skin?: TSkin | undefined) => {
  if (skin) {
    if (skin.type) {
      return claimSkinsIcons[skin.type as ESKins];
    }
  }

  return claimSkinsIcons[ESKins.DEFAULT];
};

export function getAppKeyCoin() {
  let appKeyCoin = false;

  try {
    appKeyCoin = localStorage.getItem("APP_KEY_COIN") === "true";
  } catch (error) {
    console.error("Failed to access localStorage:", error);
  }

  return appKeyCoin;
}

export function formatValue(value?: number) {
  if (value === undefined) {
    return 0;
  }

  if (value < MIN_FORMAT_COIN_VALUE) {
    return value;
  }

  const options = {
    precision: 4,
    decimalSeparator: ",",
    units: ["", "K", "M", "B", "T", "P", "E"],
  };

  return millify(value, options);
}

export function formatStatisticsValue(value?: number, precision?: number) {
  if (value === undefined) {
    return 0;
  }

  const options = {
    precision: typeof precision === 'number'  ?  precision : 3,
    decimalSeparator: ",",
    units: ["", "K", "M", "B", "T", "P", "E"],
  };

  return millify(value, options);
}

function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export async function encryptData(data: any) {
  // @ts-ignore
  const publicKey = (
    process.env.REACT_APP_ENCRYPTION_KEY as string
  )?.replaceAll("\\n", "\n");

  try {
    const rsaPublicKey = await importPublicKey(publicKey);
    const symmetricKey = await generateSymmetricKey();
    const { ciphertext, iv, authTag } = await encryptWithSymmetricKey(
      symmetricKey,
      JSON.stringify(data)
    );
    const encryptedSymmetricKey = await encryptSymmetricKey(
      rsaPublicKey,
      symmetricKey
    );

    return {
      encryptedData: window.btoa(
        // @ts-ignore
        String.fromCharCode(...new Uint8Array(ciphertext))
      ),
      encryptedSymmetricKey: window.btoa(
        // @ts-ignore
        String.fromCharCode(...new Uint8Array(encryptedSymmetricKey))
      ),
      // @ts-ignore
      iv: window.btoa(String.fromCharCode(...new Uint8Array(iv))),
      // @ts-ignore
      authTag: window.btoa(String.fromCharCode(...new Uint8Array(authTag))),
    };
  } catch (err) {
    console.error("ENCRYPTING ERROR", err);
  }

  return null;
}

function importPublicKey(pem: string) {
  const binaryDerString = window.atob(pem);
  const binaryDer = str2ab(binaryDerString);

  return window.crypto.subtle.importKey(
    "spki",
    binaryDer,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    true,
    ["encrypt"]
  );
}

async function generateSymmetricKey() {
  return window.crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256,
    },
    true,
    ["encrypt", "decrypt"]
  );
}

async function encryptWithSymmetricKey(key: CryptoKey, data: string) {
  const encodedData = new TextEncoder().encode(data);
  const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    encodedData
  );
  const encryptedBytes = new Uint8Array(encryptedData);
  const tagLength = 16; // AES-GCM tag length is 16 bytes
  const ciphertext = encryptedBytes.slice(0, -tagLength);
  const authTag = encryptedBytes.slice(-tagLength);

  return { ciphertext, iv, authTag };
}

async function encryptSymmetricKey(
  rsaPublicKey: CryptoKey,
  symmetricKey: CryptoKey
) {
  const exportedKey = await window.crypto.subtle.exportKey("raw", symmetricKey);
  const encryptedKey = await window.crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    rsaPublicKey,
    exportedKey
  );
  return encryptedKey;
}


export function convertTimestamp(timestamp: number) {
  const date = new Date(timestamp * 1000); 

  const day = ('0' + date.getDate()).slice(-2); 
  const month = ('0' + (date.getMonth() + 1)).slice(-2); 
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

 export function getCurrentUTCDateTime() {
  const now = new Date();
  const day = String(now.getUTCDate()).padStart(2, '0');
  const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Месяцы с 0, поэтому +1
  const year = now.getUTCFullYear();
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}

export const isPayPasPurchased = (payPass?: number) => {
  return payPass === PAY_PASS_MULTIPLIER || payPass === PAY_PASS_MULTIPLIER_LIGHT;
}

export const getClientTimestamp = () => {
  return Math.floor(Date.now() / 1000);
};

export const getClientTimestampMilliseconds = () => {
  return Date.now();
}

const REMAINING_TIME = "0:00:00";

export const useTimerApi = (timestamp?: number | null) => {
  const [remainingTime, setRemainingTime] = React.useState<string | null>(REMAINING_TIME);
  const [canRedeem, setCanRedeem] = React.useState(false);

  React.useEffect(() => {
    if (typeof timestamp !== 'number') {
      setCanRedeem(true);
      setRemainingTime(REMAINING_TIME);
      return;
    } else {
      setCanRedeem(false);
    }

    const startTime = timestamp;
    const eightHoursInMilliseconds = 24 * 60 * 60 * 1000; 

    // Function to calculate remaining time
    const calculateRemainingTime = () => {
      const now = Date.now();
      const elapsedTime = now - startTime;
      const remainingTime = eightHoursInMilliseconds - elapsedTime;

      if (remainingTime <= 0) {
        setRemainingTime(REMAINING_TIME);
        setCanRedeem(true);
      } else {
        const hours = Math.floor(remainingTime / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        const formattedTime = `${hours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
        setRemainingTime(formattedTime);
      }
    };

    // Initial calculation
    calculateRemainingTime();

    // Set up interval for countdown
    const interval = setInterval(calculateRemainingTime, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [timestamp]); // Re-run the effect when the timestamp changes

  const handleResetRemainingTime = () => {
    setRemainingTime(REMAINING_TIME);
  };

  return {
    remainingTime,
    canRedeem,
    isRemainingTimeEnded: remainingTime === REMAINING_TIME,
    handleResetRemainingTime,
  };
};

export const showToast = (message: string) => {
  toast(
    `${message}`,
    {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      type: "success",
    }
  );
}


export async function validateOrder(transactionId: string, userId: number) {
  const url = `https://tonapi.io/v2/events/${transactionId}`;
  const maxRetries = 5;
  let attempt = 0;
  let delay = 10000;

  while (attempt < maxRetries) {
    try {
      const { data } = await axios.get(url, { headers: { 'Content-Type': 'application/json' } });
      const orderMask = `userId: ${userId}`;

      const obj = data?.actions?.find(
        // @ts-ignore
        (value) =>
          value['TonTransfer']['comment'].includes(orderMask) && value['status'] === 'ok'
      );

      return !!obj;
    } catch (e) {
      attempt++;
      if (attempt >= maxRetries) {
        // @ts-ignore
        if (e.message === 'Request failed with status code 400') {
          throw new Error('invalid transaction id');
        }
        throw e;
      }

      await new Promise(resolve => setTimeout(resolve, delay));
      delay = Math.min(delay * 3, 60000);
    }
  }
}