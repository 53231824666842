import React from "react";

import { SmallCoin } from "../../../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../../../../consts";
import { formatValues, validateOrder } from "../../../../../../utils";
import { WSkinsModal } from "../../../../../app-modal/wskins-modal";
import { UserContext } from "../../../../../contexts/user-context";
import { ZoomInAnimation } from "../../../../../page-animation";
import { useUpdateUserExternalApi } from "../../../../../../hooks";
import { ERune, TRune } from "../../../../../hooks/rune/models";
import { TSkins, TTask } from "../../../../../feature/users/models";
import WebApp from "@twa-dev/sdk";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import { ESKins, TWSkinsItems } from "./types";
import { Oval } from "react-loader-spinner";
import {
  useGetSkinsGenerate2InvoiceMutation,
  useGetSkinsGenerate3InvoiceMutation,
  useGetSkinsGenerateInvoiceMutation,
  useGetSkinsGenerate4InvoiceMutation,
} from "../../../../../feature/users";
import {
  CHAIN,
  SendTransactionRequest,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import { beginCell, Cell, toNano } from "@ton/core";
import { TClaimState } from "../../../../../contexts/types";
import { LottieAnimationContext } from "../../../../../contexts/lottie-animation-context";
import { Stack } from "@mui/material";

// images
const skinsRetroCoinIcon = require("./img/skins_retro_coin.svg")
  .default as string;
const taskSkinsDefaultIcon = require("./img/skins_default.png") as string;
const taskPlayShurikenIcon = require("./img/skins_shuriken.png") as string;
const taskPlayCatizenIcon = require("./img/skins_catizen.svg")
  .default as string;
const taskDotIcon = require("./img/skins_dot.png") as string;
const runeIcon = require("./img/rune.png") as string;
const tonIcon = require("./img/ton.png") as string;
const ethIcon = require("./img/eth.png") as string;
const solIcon = require("./img/sol.png") as string;
const trustIcon = require("./img/skins_trust.png") as string;
const durovIcon = require("./img/free_durov_preview.png") as string;
const catsIcon = require("./img/catsPreview.png") as string;
const iceIcon = require("./img/ice-skin.png") as string;
const cristmasIcon = require("./img/christmas-skin.png") as string;
const starsIcon = require("./img/stars.png") as string;
const vinilIcon = require("./img/vinyl-min.png") as string;
const polar = require("./img/polar.png") as string;
const diamondCore = require("./img/dimond-core.png") as string;
const runic = require("./img//runic.png") as string;
const zombie = require("./img/zombie-min.png") as string;
const golden = require("./img/golden-min.png") as string;
const space = require("./img/space-min.png") as string;

const tonIconPrice = require("./img/tonIconPrice.png") as string;

function countValidProperties(obj?: any) {
  if (!obj) return 0;

  let count = 0;
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        obj[key] === true ||
        (typeof obj[key] === "string" && obj[key].trim() !== "")
      ) {
        count++;
      }
    }
  }
  return count;
}

interface IProps {
  runeType: ERune;
  counter: {
    start: number;
    end: number;
  };
  onSetRuneStep?: (
    runeStep: TRune,
    newData?: {
      balance: number;
      skins: TSkins | undefined;
      tasks: TTask | undefined;
    }
  ) => void;
  setCounter: React.Dispatch<
    React.SetStateAction<{
      start: number;
      end: number;
    }>
  >;
  dataBaseUserId?: number;
}

export const WSkins: React.FC<IProps> = ({
  setCounter,
  onSetRuneStep,
  dataBaseUserId,
}) => {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const address = wallet?.account?.address;
  const { setIsVisible } = React.useContext(LottieAnimationContext);
  const [impactOccurred] = useHapticFeedback();

  // @ts-ignore
  const notAcceptedWallet = wallet?.name === "HOT";
  // @ts-ignore
  const isNetNotAccepted = React.useMemo(
    () => wallet?.account?.chain === CHAIN.TESTNET || notAcceptedWallet,
    [notAcceptedWallet, wallet?.account?.chain]
  );

  const [failedTransaction, setFailedTransaction] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleDisconnect = async () => {
    try {
      await tonConnectUI.disconnect();
    } catch (e) {
      console.log("Error during disconnect:", e);
    }
  };

  React.useEffect(() => {
    if (isNetNotAccepted) {
      handleDisconnect();
    }
  }, [handleDisconnect, isNetNotAccepted]);

  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
  const [getSkinsGenerateInvoice, getSkinsGenerateInvoiceState] =
    useGetSkinsGenerateInvoiceMutation();
  const [getSkins2GenerateInvoice, getSkins2GenerateInvoiceState] =
    useGetSkinsGenerate2InvoiceMutation();
  const [getSkins3GenerateInvoice, getSkins3GenerateInvoiceState] =
    useGetSkinsGenerate3InvoiceMutation();
  const [getSkins4GenerateInvoice, getSkins4GenerateInvoiceState] =
    useGetSkinsGenerate4InvoiceMutation();

  const handleGetGenerateInvoice = async (update: () => void) => {
    try {
      const result = await getSkinsGenerateInvoice().unwrap();

      if (result?.result) {
        WebApp.openInvoice(result?.result, (status) => {
          if (status === "paid") {
            update();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetGenerate2Invoice = async (update: () => void) => {
    try {
      const result = await getSkins2GenerateInvoice().unwrap();

      if (result?.result) {
        WebApp.openInvoice(result?.result, (status) => {
          if (status === "paid") {
            update();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetGenerate3Invoice = async (update: () => void) => {
    try {
      const result = await getSkins3GenerateInvoice().unwrap();

      if (result?.result) {
        WebApp.openInvoice(result?.result, (status) => {
          if (status === "paid") {
            update();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetGenerate4Invoice = async (update: () => void) => {
    try {
      const result = await getSkins4GenerateInvoice().unwrap();

      if (result?.result) {
        WebApp.openInvoice(result?.result, (status) => {
          if (status === "paid") {
            update();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { user, setClaimState, isLoading } = React.useContext(UserContext);
  const claimState = user?.claim_state;
  const claimStateSkins = user?.claim_state?.skins;

  const trustWalletTasksCompleted =
    countValidProperties(user?.claim_state?.tasks?.collaboration) === 6;

  const [skinsToBuy, setSkinsToBuy] = React.useState<ESKins | null>(null);

  const handleChangeSkinToBuy = (skin: TWSkinsItems) => {
    setSkinsToBuy(skin.type);
  };

  const handleModalClose = () => {
    setSkinsToBuy(null);
  };

  const handleSelectSkin = (skin: TWSkinsItems) => {
    if (skin.selected) {
      return;
    }

    let newClaimState = {
      skins: claimState?.skins ?? {},
    };

    Object.keys(newClaimState.skins)?.forEach((key) => {
      if (newClaimState && newClaimState.skins && newClaimState?.skins?.[key]) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [key]: {
            ...newClaimState.skins[key],
            selected: false,
          },
        };
      }
    });

    if ((newClaimState.skins, skin.type)) {
      newClaimState.skins = {
        ...newClaimState.skins,
        [skin.type]: {
          purchased: true,
          selected: true,
          type: skin.type,
        },
      };
    }
    const newData = {
      skins: newClaimState.skins,
    };

    updateUserExternalApi({ ...newData })
      .then(() => {
        // @ts-ignore
        setClaimState?.((prevState) => ({
          ...(prevState || {}),
          ...newData,
        }));
      })
      .catch((e: any) => {
        console.warn(e);
      });
  };

  const W_SKINS =
    [
      {
        title: "Golden",
        price: 99,
        tonPrice: 0.4,
        selected: claimStateSkins?.[ESKins.GOLDEN]?.selected || false,
        purchased: claimStateSkins?.[ESKins.GOLDEN]?.purchased || false,
        icon: golden,
        type: ESKins.GOLDEN,
        paymentStars: true,
      },
      {
        title: "Space",
        price: 10000,
        tonPrice: 40,
        selected: claimStateSkins?.[ESKins.SPACE]?.selected || false,
        purchased: claimStateSkins?.[ESKins.SPACE]?.purchased || false,
        icon: space,
        type: ESKins.SPACE,
        paymentStars: true,
      },
      {
        title: "Zombie",
        price: 49,
        tonPrice: 0.2,
        selected: claimStateSkins?.[ESKins.ZOMBIE]?.selected || false,
        purchased: claimStateSkins?.[ESKins.ZOMBIE]?.purchased || false,
        icon: zombie,
        type: ESKins.ZOMBIE,
        paymentStars: true,
      },
      {
        title: "Polar",
        price: 99,
        tonPrice: 0.4,
        selected: claimStateSkins?.[ESKins.POLAR]?.selected || false,
        purchased: claimStateSkins?.[ESKins.POLAR]?.purchased || false,
        icon: polar,
        type: ESKins.POLAR,
        paymentStars: true,
      },
      {
        title: "Diamond core",
        price: 99,
        tonPrice: 0.4,
        selected: claimStateSkins?.[ESKins.DIAMOND_CORE]?.selected || false,
        purchased: claimStateSkins?.[ESKins.DIAMOND_CORE]?.purchased || false,
        icon: diamondCore,
        type: ESKins.DIAMOND_CORE,
        paymentStars: true,
      },
      {
        title: "Runic",
        price: 99,
        tonPrice: 0.4,
        selected: claimStateSkins?.[ESKins.RUNIC]?.selected || false,
        purchased: claimStateSkins?.[ESKins.RUNIC]?.purchased || false,
        icon: runic,
        type: ESKins.RUNIC,
        paymentStars: true,
      },
      {
        title: "Ice",
        price: 49,
        tonPrice: 0.2,
        selected: claimStateSkins?.[ESKins.ICE]?.selected || false,
        purchased: claimStateSkins?.[ESKins.ICE]?.purchased || false,
        icon: iceIcon,
        type: ESKins.ICE,
        paymentStars: true,
      },
      {
        title: "Christmas",
        price: 49,
        tonPrice: 0.2,
        selected: claimStateSkins?.[ESKins.CHRISTMAS]?.selected || false,
        purchased: claimStateSkins?.[ESKins.CHRISTMAS]?.purchased || false,
        icon: cristmasIcon,
        type: ESKins.CHRISTMAS,
        paymentStars: true,
      },
      {
        title: "Vinyl",
        price: 39,
        tonPrice: 0.15,
        selected: claimStateSkins?.[ESKins.VINYL]?.selected || false,
        purchased: claimStateSkins?.[ESKins.VINYL]?.purchased || false,
        icon: vinilIcon,
        type: ESKins.VINYL,
        paymentStars: true,
        subTitle: "*with animation",
      },
      {
        title: "TON",
        price: 0,
        selected: claimStateSkins?.[ESKins.TON]?.selected || false,
        purchased: true,
        icon: tonIcon,
        type: ESKins.TON,
      },
      {
        title: "Ethereum",
        price: 0,
        selected: claimStateSkins?.[ESKins.ETHEREUM]?.selected || false,
        purchased: true,
        icon: ethIcon,
        type: ESKins.ETHEREUM,
      },
      {
        title: "Solana",
        price: 0,
        selected: claimStateSkins?.[ESKins.SOLANA]?.selected || false,
        purchased: true,
        icon: solIcon,
        type: ESKins.SOLANA,
      },
      trustWalletTasksCompleted
        ? {
            title: "Trust Wallet",
            price: 0,
            selected: claimStateSkins?.[ESKins.TRUST]?.selected || false,
            purchased: true,
            icon: trustIcon,
            type: ESKins.TRUST,
          }
        : undefined,
      {
        title: "W-Coin Retro",
        price: 5_000,
        selected: claimStateSkins?.[ESKins.RETRO]?.selected || false,
        purchased: claimStateSkins?.[ESKins.RETRO]?.purchased || false,
        icon: skinsRetroCoinIcon,
        type: ESKins.RETRO,
      },
      {
        title: "W-Coin Default",
        price: 0,
        selected:
          Object.values(claimStateSkins || {}).find((skin) => skin.selected)
            ?.type === ESKins.DEFAULT || false,
        purchased: claimStateSkins?.[ESKins.DEFAULT]?.purchased || true,
        icon: taskSkinsDefaultIcon,
        type: ESKins.DEFAULT,
      },
      {
        title: "Shuriken",
        price: 1_000_000,
        selected: claimStateSkins?.[ESKins.SHURIKEN]?.selected || false,
        purchased: claimStateSkins?.[ESKins.SHURIKEN]?.purchased || false,
        icon: taskPlayShurikenIcon,
        type: ESKins.SHURIKEN,
      },
      {
        title: "Catizen",
        price: 1_000_000,
        selected: claimStateSkins?.[ESKins.CATIZEN]?.selected || false,
        purchased: claimStateSkins?.[ESKins.CATIZEN]?.purchased || false,
        icon: taskPlayCatizenIcon,
        type: ESKins.CATIZEN,
      },
      {
        title: "Dot",
        price: 1_000_000,
        selected: claimStateSkins?.[ESKins.DOT]?.selected || false,
        purchased: claimStateSkins?.[ESKins.DOT]?.purchased || false,
        icon: taskDotIcon,
        type: ESKins.DOT,
      },
      {
        title: "Cats",
        price: 1_000_000,
        selected: claimStateSkins?.[ESKins.CATS]?.selected || false,
        purchased: claimStateSkins?.[ESKins.CATS]?.purchased || false,
        icon: catsIcon,
        type: ESKins.CATS,
      },
      {
        title: "Rune",
        price: 5_000,
        selected: claimStateSkins?.[ESKins.RUNE]?.selected || false,
        purchased: claimStateSkins?.[ESKins.RUNE]?.purchased || false,
        icon: runeIcon,
        type: ESKins.RUNE,
      },
      {
        title: "#FREEDUROV",
        price: 0,
        selected: claimStateSkins?.[ESKins.FREE_DUROV]?.selected || false,
        purchased: claimStateSkins?.[ESKins.FREE_DUROV]?.purchased || false,
        icon: durovIcon,
        type: ESKins.FREE_DUROV,
      },
    ].filter((i) => i) || [];

  const currentSkins =
    W_SKINS.find((item) => item!.type === skinsToBuy) || null;

  const handBuySkin = (paynentType?: "TON" | "STARS") => {
    if (!currentSkins) {
      return;
    }

    if (claimState?.id) {
      let newClaimState = { ...claimState };
      newClaimState.skins = newClaimState.skins ?? {};

      if (currentSkins.type === ESKins.RETRO) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.RETRO]: {
            purchased: true,
            selected: false,
            type: ESKins.RETRO,
          },
        };
      } else if (currentSkins.type === ESKins.SHURIKEN) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.SHURIKEN]: {
            purchased: true,
            selected: false,
            type: ESKins.SHURIKEN,
          },
        };
      } else if (currentSkins.type === ESKins.CATIZEN) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.CATIZEN]: {
            purchased: true,
            selected: false,
            type: ESKins.CATIZEN,
          },
        };
      } else if (currentSkins.type === ESKins.DOT) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.DOT]: {
            purchased: true,
            selected: false,
            type: ESKins.DOT,
          },
        };
      } else if (currentSkins.type === ESKins.SOLANA) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.SOLANA]: {
            purchased: true,
            selected: false,
            type: ESKins.SOLANA,
          },
        };
      } else if (currentSkins.type === ESKins.ETHEREUM) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.ETHEREUM]: {
            purchased: true,
            selected: false,
            type: ESKins.ETHEREUM,
          },
        };
      } else if (currentSkins.type === ESKins.TON) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.TON]: {
            purchased: true,
            selected: false,
            type: ESKins.TON,
          },
        };
      } else if (currentSkins.type === ESKins.CATS) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.CATS]: {
            purchased: true,
            selected: false,
            type: ESKins.CATS,
          },
        };
      } else if (currentSkins.type === ESKins.ICE) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.ICE]: {
            purchased: true,
            selected: false,
            type: ESKins.ICE,
          },
        };
      } else if (currentSkins.type === ESKins.CHRISTMAS) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.CHRISTMAS]: {
            purchased: true,
            selected: false,
            type: ESKins.CHRISTMAS,
          },
        };
      } else if (currentSkins.type === ESKins.VINYL) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.VINYL]: {
            purchased: true,
            selected: false,
            type: ESKins.VINYL,
          },
        };
      } else if (currentSkins.type === ESKins.TRUST) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.TRUST]: {
            purchased: true,
            selected: false,
            type: ESKins.TRUST,
          },
        };
      } else if (
        currentSkins.type === ESKins.ZOMBIE ||
        currentSkins.type === ESKins.GOLDEN ||
        currentSkins.type === ESKins.SPACE
      ) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [currentSkins.type]: {
            purchased: true,
            selected: false,
            type: currentSkins.type,
          },
        };
      } else if (
        currentSkins.type === ESKins.POLAR ||
        currentSkins.type === ESKins.DIAMOND_CORE ||
        currentSkins.type === ESKins.RUNIC
      ) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [currentSkins.type]: {
            purchased: true,
            selected: false,
            type: currentSkins.type,
          },
        };
      } else if (currentSkins.type === ESKins.RUNE) {
        // Specific logic for RUNE skin
        const updatedSkins: TSkins = {};

        Object.entries({ ...newClaimState.skins }).forEach(([key, skin]) => {
          updatedSkins[key] = { ...skin, selected: false };
        });

        newClaimState.tasks = {
          ...newClaimState.tasks,
          // @ts-ignore
          rune: { step: ERune.START, completed: false },
        };

        newClaimState.skins = {
          ...updatedSkins,
          [ESKins.RUNE]: { purchased: true, selected: true, type: ESKins.RUNE },
        };
      } else if (currentSkins.type === ESKins.FREE_DUROV) {
        newClaimState.skins = {
          ...newClaimState.skins,
          [ESKins.FREE_DUROV]: {
            purchased: true,
            selected: false,
            type: ESKins.FREE_DUROV,
          },
        };
      }

      let newData = {
        balance: newClaimState.balance,
        skins: newClaimState.skins,
        tasks: newClaimState.tasks,
      };

      const handleBuy = () => {
        updateUserExternalApi({ ...newData })
          .then(() => {
            // @ts-ignore
            setClaimState?.((prevState) => ({
              ...(prevState || {}),
              ...newData,
            }));
            setCounter({ start: claimState!.balance, end: newData!.balance });

            if (currentSkins.type === ESKins.RUNE) {
              onSetRuneStep?.(
                {
                  step: ERune.START,
                  completed: false,
                },
                newData
              );
            }
          })
          .catch((e: any) => {
            console.warn(e);
          });
      };

      if (currentSkins.paymentStars || currentSkins.tonPrice) {
        newData = {
          balance: newClaimState.balance,
          skins: newClaimState.skins,
          tasks: newClaimState.tasks,
        };

        if (paynentType === "STARS") {
          if (currentSkins.type === ESKins.VINYL) {
            handleGetGenerate2Invoice(handleBuy);
          } else if (
            currentSkins.type === ESKins.POLAR ||
            currentSkins.type === ESKins.DIAMOND_CORE ||
            currentSkins.type === ESKins.RUNIC ||
            currentSkins.type === ESKins.GOLDEN
          ) {
            handleGetGenerate3Invoice(handleBuy);
          } else if (currentSkins.type === ESKins.SPACE) {
            handleGetGenerate4Invoice(handleBuy);
          } else {
            handleGetGenerateInvoice(handleBuy);
          }
        } else {
          handleSentTransaction(handleBuy);
        }
      } else {
        if (typeof newClaimState.balance === "number") {
          newData = {
            balance: newClaimState.balance - currentSkins.price,
            skins: newClaimState.skins,
            tasks: newClaimState.tasks,
          };
        }
        handleBuy();
      }
    }
  };

  async function handleSentTransaction(handBuySkin: () => void) {
    setFailedTransaction(false);

    if (isNetNotAccepted) {
      handleDisconnect();
    } else {
      if (!wallet || !wallet.account?.address) {
        console.error("Wallet not connected");
        return;
      }

      if (address && currentSkins?.tonPrice && dataBaseUserId) {
        const transaction: SendTransactionRequest = {
          validUntil: Math.floor(Date.now() / 1000) + 3600,
          messages: [
            {
              address: "UQAq4SXiWmgsve0zqMzZRmCErBC_CjZjU_P0Csptw5XlTU3O",
              amount: toNano(currentSkins.tonPrice).toString(),
              payload: beginCell()
                .storeUint(0, 32)
                .storeStringTail(
                  `type: skins,
                  item: ${currentSkins?.type || ""},
                  wallet: ${
                    // @ts-ignore
                    wallet?.name || ""
                  },
                  userId: ${dataBaseUserId}`
                )
                .endCell()
                .toBoc()
                .toString("base64"),
            },
          ],
        };

        try {
          const res = await tonConnectUI.sendTransaction(transaction);

          if (res?.boc) {
            const cell = Cell.fromBase64(res.boc);
            const buffer = cell.hash();
            const hashHex = buffer.toString("hex");

            const newData = {
              boosts: { ...(claimState?.boosts || {}), recoveryTapping: true },
            } as unknown as TClaimState;

            if (hashHex && newData && dataBaseUserId) {
              setIsProcessing(true);
              const checkTransaction = await validateOrder(
                hashHex,
                dataBaseUserId
              );

              if (checkTransaction) {
                handBuySkin();
                setFailedTransaction(false);
                setIsProcessing(false);
                impactOccurred("heavy");
                setIsVisible?.(true);
                setFailedTransaction(false);

                const timeoutId = setTimeout(() => {
                  setIsVisible?.(false);
                }, 4000);
              } else if (checkTransaction === false) {
                setIsProcessing(false);
                setFailedTransaction(true);
              }
            }
          }
        } catch (e) {
          setIsProcessing(false);
          console.error(e, "Transaction error");
        }
      }
    }
  }

  const status = (item: TWSkinsItems) => {
    if (item.type === ESKins.FREE_DUROV && !item.purchased) {
      return "Price: Freedom";
    } else if (item.selected) {
      return "Selected";
    } else if (item.price === 0 || item.purchased) {
      return "You own it";
    }

    return formatValues(item.price);
  };

  const isRunePurchased =
    currentSkins?.purchased && currentSkins?.type === ESKins.RUNE;

  const isLoadingApp =
    updateUserState?.isLoading ||
    getSkinsGenerateInvoiceState.isLoading ||
    getSkins2GenerateInvoiceState.isLoading ||
    getSkins3GenerateInvoiceState.isLoading ||
    getSkins4GenerateInvoiceState.isLoading ||
    isProcessing;

  return (
    <>
      {!!skinsToBuy && currentSkins && !isRunePurchased && (
        <div
          className={`overlay app-modal-root ${!!currentSkins ? "blur" : ""}`}
        ></div>
      )}
      {isLoadingApp && (
        <div className="app-spinner" style={{ top: 0, left: 0 }}>
          <Oval
            visible={isLoadingApp}
            height="100"
            width="100"
            color="#9747FF"
            secondaryColor="rgb(225, 219, 239)"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      <WSkinsModal
        open={!!currentSkins && !isRunePurchased}
        onClose={handleModalClose}
        onUpdate={(paymentType) => handBuySkin(paymentType)}
        skinsToBuy={currentSkins}
        balance={claimState?.balance || 0}
        isPurchased={currentSkins?.purchased}
        isLoading={isLoading}
        isWalletConnected={tonConnectUI.connected}
        address={address}
        tonConnectUI={tonConnectUI}
        onDisconnect={handleDisconnect}
        failedTransaction={failedTransaction}
      />
      {W_SKINS.map((item, index) => {
        return (
          <div
            key={index}
            className="main-boosters-root"
            onClick={
              item!.purchased
                ? // @ts-ignore
                  () => handleSelectSkin(item)
                : // @ts-ignore
                  () => handleChangeSkinToBuy(item)
            }
          >
            <div className="main-boosters-left">
              <div className="main-boosters-avatar">
                <ZoomInAnimation>
                  <img
                    width="75px"
                    height={item?.type === ESKins.ICE ? "auto" : "75px"}
                    src={item!.icon}
                    alt=""
                  />
                </ZoomInAnimation>
              </div>
              <div className="main-boosters-description-wrapper ">
                <p className="green-color">{item!.title}</p>
                {/* <p className="green-color">{item!.subTitle}</p> */}
                {!(item!.purchased || item!.selected) && item?.paymentStars && (
                  <Stack flexDirection="row" gap={0.5}>
                    <img src={tonIconPrice} alt="" width="15px" height="15px" />

                    <p className="main-boosters-price common-color">
                      {/** @ts-ignore */}
                      {item.tonPrice}
                    </p>
                  </Stack>
                )}
                <div
                  className="main-boosters-description"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Stack flexDirection="row" gap={0.5}>
                    {!(item!.purchased || item!.selected) &&
                      (item?.paymentStars ? (
                        <img
                          src={starsIcon}
                          alt=""
                          width="15px"
                          height="15px"
                        />
                      ) : (
                        <SmallCoin
                          width={BALANCE_COIN_SIZE_SMALL}
                          height={BALANCE_COIN_SIZE_SMALL}
                        />
                      ))}
                    <p className="main-boosters-price common-color">
                      {/** @ts-ignore */}
                      {status(item)}
                    </p>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
