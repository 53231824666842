export enum ECampaign {
    SAGA_1 = "saga_1",
    SAGA_2 = "saga_2",
    SAGA_3 = "saga_3",
    SAGA_4 = "saga_4",
    SPACE_SHOW_0 = "space_show_0",
    SPACE_SHOW_1 = "space_show_1",
    SPACE_SHOW_2 = "space_show_2",
    SPACE_SHOW_3 = "space_show_3",
    SPACE_SHOW_4 = "space_show_4",
    SPACE_SHOW_5 = "space_show_5",
    SPACE_SHOW_6 = "space_show_6",
    SPACE_SHOW_7 ="space_show_7",
    SPACE_SHOW_8 = "space_show_8",
    SPACE_SHOW_9 = "SPACE_SHOW_9",
    SPACE_SHOW_10 = "SPACE_SHOW_10",
    TON_1 = "TON_1",
    TON_2 = "TON_2",
    TON_3 = "TON_3",
    TON_4 = "TON_4",
    TON_5 = "TON_5",
    TON_6 = "TON_6",
    TON_7 = "TON_7",
    TON_8 = "TON_8",
    TON_9 = "TON_9",
    TON_10 = "TON_10",
    TON_11 = "TON_11",
    TON_12 = "TON_12",
    TON_13 = "TON_13",
    TON_14 = "TON_14",
    TON_15 = "TON_15",
    TON_16 = "TON_16",
    TON_17 = "TON_17",
    TON_18 = "TON_18",
    TON_19 = "TON_19",
    TON_20 = "TON_20",
    TON_21 = "TON_21",
    TON_22 = "TON_22",
    TON_23 = "TON_23",
    TON_24 = "TON_24",
    TON_25 = "TON_25",
    TON_26 = "TON_26",
    TON_27 = "TON_27",
    TON_28 = "TON_28",
    TON_29 = "TON_29",
    TON_30 = "TON_30",
    TON_31 = "TON_31",
    TON_32 = "TON_32",
    TON_33 = "TON_33",
    TON_34 = "TON_34",
    TON_35 = "TON_35",
    TON_36 = "TON_36",
    TON_37 = "TON_37",
  }
  
  export interface ICampaign {
      icon: string;
      link: string;
      type: ECampaign;
      title: string;
      btnLabel: string;
      reward: number;
      is_done?: boolean;
      verify?: boolean;
      code?: string;
  }

  export enum ETab {
    SAGA = "SAGA",
    SPACE_SHOW = "SPACE_SHOW",
    TON_VIDEO = "TON_VIDEO",
  }
  