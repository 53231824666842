import { EKeyType, TKey } from "./models";

export const CHEST_ONBOARDING_KEY = 'CHEST_ONBOARDING_KEY_UPDATED';


export enum EChestNames {
    STARTER = 'STARTER',
    GUARDIAN = 'GUARDIAN',
    TREASURE = 'TREASURE',
    WCOSMIC = 'W-COSMIC',
}

export const KEYS: Record<EKeyType, TKey> = {
    [EKeyType.Bronze]: {
        type: EKeyType.Bronze,
        amount: 1,
        isBestDeal: false,
        ton: 0.08,
        stars: 15,
    },
    [EKeyType.Silver]: {
        type: EKeyType.Silver,
        amount: 10,
        isBestDeal: false,
        ton: 0.3,
        stars: 70,
    },
    [EKeyType.Gold]: {
        type: EKeyType.Gold,
        amount: 100,
        isBestDeal: false,
        ton: 2,
        stars: 600,
    },
    [EKeyType.Platinum]: {
        type: EKeyType.Platinum,
        amount: 1000,
        isBestDeal: true,
        ton: 15,
        stars: 4500,
    },
    [EKeyType.Diamond]: {
        type: EKeyType.Diamond,
        amount: 5000,
        isBestDeal: false,
        ton: 45,
        stars: 15000,
    },
    [EKeyType.Brilliant]: {
        type: EKeyType.Brilliant,
        amount: 10000,
        isBestDeal: false,
        ton: 70, 
        stars: 26000,
    },
};