import React from "react";

import { Box, Button, Stack } from "@mui/material";

import { CSSTransition } from "react-transition-group";
import { Spinner } from "../../spinner";
import { useInputCheckCode } from "../../page-chests/modals/key-shop-content/check-transaction/utils";

import { useUpdateMintNftAirdropMutation } from "../../feature/users";
import { LottieAnimationContext } from "../../contexts/lottie-animation-context";

interface IProps {
  open: boolean;
  close: () => void;
}

export const CheckModal: React.FC<IProps> = ({ open, close }) => {
  const [updateGas, updateGasState] = useUpdateMintNftAirdropMutation();
  const { setIsVisible } = React.useContext(LottieAnimationContext);

  const { inputCode, onInputChange, errorMessage, setErrorMessage } =
    useInputCheckCode();

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  const handleCheckTransaction = () => {
    if (inputCode) {
      updateGas({
        transaction: inputCode,
      })
        .unwrap()
        .then((res) => {
          console.log({ res });

          // @ts-ignore
          if (res.status === "success") {
            setIsVisible?.(true);
            setErrorMessage(false);

            const timeoutId = setTimeout(() => {
              setIsVisible?.(false);
            }, 4000);
          } else {
            setErrorMessage(true);
          }
        })
        .catch((e) => {
          if (e.data.error) {
            setErrorMessage(true);
          }
        });
    }
  };

  return (
    <>
      {open && (
        <div className={`overlay app-modal-root ${open ? "blur" : ""}`}></div>
      )}
      <CSSTransition
        in={open}
        timeout={500}
        classNames="fade"
        unmountOnExit
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          height: "70vh",
          alignItems: "end",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          top: "unset",
        }}
      >
        <div className="overlay app-modal-root">
          <Stack
            flexDirection="column"
            justifyContent="space-between"
            style={{
              justifyContent: "end",
              padding: "20px",
              width: "100%",
            }}
          >
            <Spinner
              isLoading={updateGasState.isLoading}
              label="Verifying transaction…"
              marginTop="-26px"
              marginLeft="-19px"
            />
            <Stack gap="8px">
              <p
                className="ab"
                style={{
                  fontSize: "140px",
                  color: "#fff",
                  margin: "0 auto",
                  marginBottom: "20px",
                }}
              >
                #
              </p>
              <p
                style={{
                  fontSize: "15px",
                  color: "#fff",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                Input your transaction hash to claim your NFT
              </p>
              <Box
                className="okx-input-wrapper"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "32px",
                }}
              >
                <>
                  <Stack gap={1} width="100%">
                    <input
                      value={inputCode ?? ""}
                      type="text"
                      className="okx-mobile-input"
                      placeholder="Input transaction here"
                      onChange={(e) => onInputChange(e.target.value as string)}
                      onBlur={scrollToBottom}
                      style={{
                        color: "#fff",
                        height: "46px",
                        borderRadius: "22px",
                      }}
                    />
                  </Stack>
                  <Button
                    onClick={handleCheckTransaction}
                    className={`ab ${!inputCode && "btn-disabled"}`}
                    style={{
                      minWidth: "110px",
                      borderRadius: "22px",
                      background: "#9747FF",
                      color: "#fff",
                      height: "46px",
                      textTransform: "none",
                    }}
                  >
                    Claim
                  </Button>
                </>
              </Box>
              <p
                className={`azure-games-validation-label ${
                  errorMessage && "azure-games-validation-label-visible"
                }`}
                style={{
                  fontSize: "13px",
                  color: "red",
                  marginBottom: "8px",
                  height: "30px",
                }}
              >
                {errorMessage
                  ? "Error occurred while minting NFT airdrop for user"
                  : null}
              </p>
            </Stack>
            <Stack gap={1} justifyContent="center" width="100%">
              <Button
                className="ab"
                sx={{
                  color: "#fff",
                  width: "100%",
                  height: "54px",
                  backgroundColor: "red",
                  borderRadius: "22px",
                  fontSize: "16px",
                  textTransform: "none",
                }}
                onClick={close}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </div>
      </CSSTransition>
    </>
  );
};
