import React from "react";

import { Stack, Typography } from "@mui/material";
import { truncateString } from "../../wallet/utils";
import { Address } from "@ton/core";

interface IProps {
  isEligible: boolean;
  databaseWalletAddress?: string;
  isSentStatus: boolean;
}

export const Heading: React.FC<IProps> = ({
  isEligible,
  databaseWalletAddress,
  isSentStatus,
}) => {
  return (
    <Stack
      gap={2}
      className="galaxy-color"
      style={{
        width: "100%",
        fontSize: "48px",
        justifyContent: "center",
      }}
    >
      <Stack gap={2}>
        <Typography
          className="ab"
          style={{
            fontSize: "48px",
            lineHeight: "0.9",
          }}
          textAlign="center"
        >
          W-Coin
        </Typography>
        <Typography
          className="ab"
          style={{
            fontSize: "48px",
            lineHeight: "0.7",
          }}
          textAlign="center"
        >
          Premarket
        </Typography>
      </Stack>
      <Stack alignItems="center" style={{ fontSize: "14px" }}>
        {isEligible ? (
          <Stack>
            <Typography color="#D7D7D7">
              {isSentStatus
                ? "Congratulations! Vouchers was sent to your wallet"
                : "Vouchers are ready to be claimed!"}
            </Typography>
            {isSentStatus && databaseWalletAddress ? (
              <Typography
                color="#D7D7D7"
                style={{
                  margin: "0 auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <p
                    className="paPassAddress"
                    style={{
                      color: "#D7D7D7",
                    }}
                  >
                    Wallet address:{" "}
                    {truncateString(
                      Address.parse(databaseWalletAddress).toString({
                        bounceable: false,
                      })
                    )}
                  </p>
                </div>
              </Typography>
            ) : null}
          </Stack>
        ) : (
          <>
            <Typography color="#D7D7D7">
              Trade W-Coin using vouchers,
            </Typography>
            <Typography color="#D7D7D7" style={{ fontSize: "14px" }}>
              before token is issued.
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};
