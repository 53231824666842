export type TWSkinsItems = {
  icon: string;
  title: string;
  price: number;
  selected: boolean;
  purchased: boolean;
  type: ESKins;
  paymentStars?: boolean;
  tonPrice?: number;
  subTitle?: string;
};

export enum ESKins {
  RETRO = "retro",
  DEFAULT = "default",
  SHURIKEN = "shuriken",
  CATIZEN = "catizen",
  TON = "ton",
  SOLANA = "solana",
  DOT = "dot",
  ETHEREUM = "ethereum",
  TRUST = "trust",
  RUNE = "rune",
  FREE_DUROV = "free__durov",
  CATS = "cats",
  ICE = "ice",
  CHRISTMAS = "christmas",
  VINYL = "vinyl",
  RUNIC = "runic",
  DIAMOND_CORE = "diamond_core",
  POLAR = "polar",
  ZOMBIE = "zombie",
  SPACE = "space",
  GOLDEN = "golden",
}
