import React from "react";

const conditionsIcon = require("../content/img/conditions-icon.png") as string;

interface IProps {
  onInfoModalOpen: () => void;
}

export const Header: React.FC<IProps> = ({ onInfoModalOpen }) => {
  return (
    <div
      className="page-claim-header"
      style={{
        position: "relative",
      }}
    >
      <div className="page-claim-header-root">
        <p className="green-color ab" style={{ fontSize: "22px" }}>
          W-AIRDROP
        </p>
        <div className="icons page-claim-icons">
          <img
            onClick={onInfoModalOpen}
            width="20.3px"
            height="20.3px"
            src={conditionsIcon}
            alt=""
            style={{ marginLeft: "12px" }}
          />
        </div>
      </div>
    </div>
  );
};
