import React from "react";
import { Button, Typography } from "@mui/material";

import { CSSTransition } from "react-transition-group";

interface IProps {
  show: boolean;
  onClose: () => void;
}
export const ConditionsModal: React.FC<IProps> = ({ show, onClose }) => {
  return (
    <>
      <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit>
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          onClick={(e) => e.stopPropagation()}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            zIndex: 13,
            height: "unset",
            borderRadius: "27px",
            backgroundColor: "#fff",
            padding: "24px",
          }}
        >
          <div className="app-modal-content task-modal-content" style={{}}>
            <div className="task-modal-desc">
              <Typography
                style={{
                  fontSize: "24px",
                }}
                className="ab"
              >
                Who is Active Mate?
              </Typography>
              <ul
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: "20px",
                }}
              >
                <li>Connected TON Wallet</li>
                <li>Completed 3 tasks</li>
                <li>100K W-Coin earned</li>
              </ul>
              <Button
                onClick={onClose}
                className="ab"
                style={{
                  backgroundColor: "#9747FF",
                  color: "#fff",
                  width: "135px",
                  borderRadius: "16px",
                  marginTop: "16px",
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
