import React from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import { Address } from "@ton/core";

import { truncateString } from "../../../wallet/utils";

import "./index.scss";

// images
const editIcon = require("./img/edit-icon.png") as string;

interface IProps {
  address?: string;
  databaseWalletAddress?: string;
  handleDisconnect: () => Promise<void>;
  handleConnectWallet: () => void;
  canShowConnectedBtn: boolean;
  isEligible?: boolean;
  status?: "PENDING" | "NOT VERIFIED" | "SENT";
  handleGasPaymentConnect: () => Promise<void>;
  failedTransaction: boolean;
  handleSentGasTransaction: () => Promise<void>;
  gasAddress: string | null;
  handleCheckModalOpen: (val: boolean) => void;
  isSentStatus: boolean;
}

export const Actions: React.FC<IProps> = ({
  address,
  databaseWalletAddress,
  handleDisconnect,
  handleConnectWallet,
  canShowConnectedBtn,
  isEligible,
  status,
  handleGasPaymentConnect,
  handleSentGasTransaction,
  gasAddress,
  failedTransaction,
  handleCheckModalOpen,
  isSentStatus,
}) => {
  const getBtn = () => {
    if (isEligible) {
      if (status === "PENDING") {
        return (
          <Stack gap={2} style={{ width: "100%" }}>
            {failedTransaction ? (
              <Typography style={{ color: "red" }}>
                Not enough TON funds
              </Typography>
            ) : null}
            <Button
              onClick={() => {
                if (gasAddress) {
                  handleSentGasTransaction();
                } else {
                  handleGasPaymentConnect();
                }
              }}
              className="nft-action-btn ab"
              style={{ width: "100%" }}
            >
              Claim
            </Button>
          </Stack>
        );
      } else {
        return (
          <Button className="nft-action-btn ab" style={{ width: "100%" }}>
            Open Getgems.io
          </Button>
        );
      }
    }

    if (canShowConnectedBtn) {
      return (
        <Button
          onClick={handleConnectWallet}
          className="nft-action-btn ab"
          style={{ width: "100%" }}
        >
          Connect wallet
        </Button>
      );
    } else {
      return <Box width="285.24px" height="66px"></Box>;
    }
  };

  const addressContent = () => {
    if (isEligible && status === "PENDING") {
      return (
        <Box height="22px">
          <p className="white-color paPassAddress">
            Already have a transaction ID?{" "}
            <span
              onClick={() => handleCheckModalOpen(true)}
              style={{
                color: "#C395FF",
              }}
            >
              Redeem
            </span>
          </p>
        </Box>
      );
    }

    if (status === "SENT") {
      return <Box height="22px"></Box>;
    }

    if (databaseWalletAddress) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p className="white-color paPassAddress">
            TON wallet address:{" "}
            {truncateString(
              Address.parse(databaseWalletAddress || "").toString({
                bounceable: false,
              })
            )}
          </p>
          <img src={editIcon} alt="" onClick={handleDisconnect} />
        </div>
      );
    } else if (address) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p className="white-color paPassAddress">
            TON wallet address:{" "}
            {truncateString(
              Address.parse(address).toString({
                bounceable: false,
              })
            )}
          </p>
          <img src={editIcon} alt="" onClick={handleDisconnect} />
        </div>
      );
    } else {
      return <Box height="22px"></Box>;
    }
  };

  return (
    <Stack width="100%" justifyContent="center" alignItems="center" gap="20px">
      {gasAddress && isEligible && !isSentStatus ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <p className="white-color paPassAddress">
            {truncateString(
              Address.parse(gasAddress).toString({
                bounceable: false,
              })
            )}
          </p>
          <img src={editIcon} alt="" onClick={handleGasPaymentConnect} />
        </div>
      ) : null}

      {getBtn()}
      {addressContent()}
    </Stack>
  );
};
