import React from "react";

import { TAppUser, TClaimState } from "../../../contexts/types";
import { useUpdateUserExternalApi } from "../../../../hooks";
import { useUpdateVerifyTeafiMutation } from "../../../feature/users";

import { ECampaign, ICampaign } from "./models";
import { isTimestampOlderThan24Hours } from "./utils";

// images
const connectedWalletIcon = require("./img/connectedWalletIcon.png") as string;
const followXIcon = require("./img/followXIcon.png") as string;
const joinTgIcon = require("./img/joinTgIcon.png") as string;
const joinTgIconCommunity = require("./img/discord.svg").default as string;

export const useTeaFiApi = (
  user: TAppUser,
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>
) => {
  // @ts-ignore
  const timestamp = user?.claim_state?.tasks?.tea_fi?.coldownTimeStamp;

  const payPassMultiplier = user?.claim_state?.payPassMultiplier;

  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();
  const [activeTask, setActiveTask] = React.useState<ICampaign | null>(null);

  const [verifyTeaFi, verifyTeaFiState] = useUpdateVerifyTeafiMutation();

  const handleClaimTask = (task: ICampaign | null) => {
    if (!task) {
      return;
    }

    const payPassMultiplier = user?.claim_state?.payPassMultiplier;

    if (task && payPassMultiplier) {
      const newData = {
        balance:
          (user.claim_state?.balance || 0) + task.reward * payPassMultiplier,
        balance_from_clicks:
          (user.claim_state?.balance_from_clicks || 0) + task.reward,
        // @ts-ignore
        tasks: user?.claim_state.tasks
          ? {
              ...user.claim_state.tasks,
              tea_fi: {
                // @ts-ignore
                ...(user.claim_state.tasks.tea_fi || {}),
                [task.type]: true,
                coldownTimeStamp:
                  task.type === ECampaign.CONNECT_WALLET
                    ? Date.now() + 24 * 60 * 60 * 1000
                    : // @ts-ignore
                      user?.claim_state?.tasks?.tea_fi?.coldownTimeStamp,
                timeStamp:
                  task.type === ECampaign.CONNECT_WALLET
                    ? Date.now() + 24 * 60 * 60 * 1000
                    : // @ts-ignore
                      user?.claim_state?.tasks?.tea_fi?.timeStamp,
              },
            }
          : {
              // @ts-ignore
              tea_fi: { [task.type]: true },
            },
      } as unknown as TClaimState;
      updateUserExternalApi({ ...newData })
        .then(() => {
          setClaimState?.((prevState) => ({
            ...prevState,
            ...newData,
          }));
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  };

  const onTaskClick = (task: ICampaign) => {
    if (task.is_done && task.type !== ECampaign.CONNECT_WALLET) {
      return;
    }

    switch (task.type) {
      case ECampaign.CONNECT_WALLET:
        if (timestamp === undefined) {
          setActiveTask(task);
        } else if (
          isTimestampOlderThan24Hours(
            // @ts-ignore
            timestamp
          )
        ) {
          setActiveTask(task);
        }
        return;

      case ECampaign.FOLLOW_X:
        setActiveTask(task);
        return;
      case ECampaign.JOIN_TG:
        setActiveTask(task);
        return;
      case ECampaign.JOIN_TG_COMMUNITY:
        setActiveTask(task);
        return;

      default:
        return;
    }
  };
  // @ts-ignore
  const id = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || "";

  const handleTeafiVerify = () => {
    return verifyTeaFi();
  };

  const tasksList: ICampaign[] = React.useMemo(
    () => [
      {
        icon: connectedWalletIcon,
        link: `https://app.tea-fi.com/?telegramId=${id}`,
        type: ECampaign.CONNECT_WALLET,
        title: "Connect wallet & Do 1 on-chain activity.",
        subtitle: "It might take up to 1 hour to confirm.",
        reward: 1_000_000,
        is_done: false,
        btnLabel: "Complete Task",
        errorMessage: "You have not connect wallet & Do 1 on-chain activity.",
        externalLink: true,
      },
      {
        icon: followXIcon,
        link: "https://x.com/TeaFi_Official",
        type: ECampaign.FOLLOW_X,
        title: "Follow Tea-Fi on X",
        reward: 100_000,
        is_done: false,
        btnLabel: "Follow Tea-Fi on X",
        externalLink: true,
      },
      {
        icon: joinTgIcon,
        link: "https://t.me/TeaFi_Official",
        type: ECampaign.JOIN_TG,
        title: "Join Tea-Fi in TG",
        reward: 100_000,
        is_done: false,
        btnLabel: "Join Tea-Fi in TG",
        errorMessage: "You have not join Tea-Fi in TG.",
        externalLink: false,
        tgName: "TeaFi_Official",
      },
      {
        icon: joinTgIconCommunity,
        link: "https://discord.gg/f7NFGcuKFu",
        type: ECampaign.JOIN_TG_COMMUNITY,
        title: "Join Tea-Fi community on Discord",
        reward: 100_000,
        is_done: false,
        btnLabel: "Join Tea-Fi on Discord",
        externalLink: true,
      },
    ],
    [user?.claim_state?.tasks?.tea_fi]
  );

  const tasksToDisplay = () => {
    return tasksList?.map((item: ICampaign) => {
      if (item.type === ECampaign.CONNECT_WALLET) {
        return {
          ...item,
          // @ts-ignore
          is_done: timestamp
            ? !isTimestampOlderThan24Hours(
                // @ts-ignore
                timestamp
              )
            : false,
        };
      } else {
        return {
          ...item,
          // @ts-ignore
          is_done:
            (user?.claim_state?.tasks &&
              // @ts-ignore
              user.claim_state.tasks?.tea_fi?.[item.type]) ||
            false,
        };
      }
    });
  };
  console.log(tasksToDisplay());

  const completedTasks =
    tasksToDisplay().filter((task) => task.is_done)?.length || 0;
  const allTasks = tasksToDisplay()?.length || 0;
  const isAllTasksDone = completedTasks === allTasks;

  const handleModalClose = () => {
    setActiveTask(null);
  };

  return {
    completedTasks,
    allTasks,
    tasksToDisplay,
    isAllTasksDone,
    payPassMultiplier,
    onTaskClick,
    activeTask,
    onModalClose: handleModalClose,
    isLoading: updateUserState.isLoading || verifyTeaFiState.isLoading,
    onClaimTask: handleClaimTask,
    onTeafiVerify: handleTeafiVerify,
    timestamp,
  };
};
