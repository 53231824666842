import React from "react";

import { ETab, ETask, ITask } from "../type";
import { AzureGamesCampaign } from "../campaigns";
import { TaskList } from "../task-list";
import { TAppUser, TClaimState } from "../../contexts/types";
import { TRune } from "../../hooks/rune/models";
import { TaskCounterAndDescription } from "../task-counter-and-description";
import { ToggleGroup } from "../toggle-group";
import { TeaFi } from "../campaigns/tea-fi";
import { Youtube } from "../campaigns/youtube";
import { MinerTycoon } from "../campaigns/miner-tycoon";
import { AdsgramTasks } from "../campaigns/adsgram-tasks";

interface IProps {
  activeTab: ETab;
  user: TAppUser;
  setClaimState:
    | React.Dispatch<React.SetStateAction<TClaimState | undefined>>
    | undefined;
  can_update_adsgram_ads: number;
  can_update_onclicka_ads: number;
  WALLET_TASK: ITask;
  THREE_FRIENDS_TASK: ITask;
  activeTasksList: {
    [key: string]: ITask[];
  };
  onClaimTask: (ct: ETask | string | null) => Promise<void>;
  onChangeCurrentIndexTask: (ct: ETask | string | null) => void;
  readyToClaim: {};
  onSetShowVerifyModal: (value: boolean) => void;
  onSetShowShareModal: () => void;
  onShowAdgramsAdvert: () => void;
  onShowOnclickaAdvert: () => void;
  completedTasksList: {
    [key: string]: ITask[];
  };
  isCompletedTasksListEmpty: boolean;
  onSetRuneStep: ((runeStep: TRune) => void) | undefined;
  onActiveTaskChange: (tab: ETab) => void;
  payPassMultiplier?: number;
  onCheckCodeModal: (value: boolean) => void;
}

export const Content: React.FC<IProps> = ({
  activeTab,
  user,
  setClaimState,
  can_update_adsgram_ads,
  can_update_onclicka_ads,
  WALLET_TASK,
  THREE_FRIENDS_TASK,
  activeTasksList,
  onClaimTask,
  onChangeCurrentIndexTask,
  readyToClaim,
  onSetShowVerifyModal,
  onSetShowShareModal,
  onShowAdgramsAdvert,
  onShowOnclickaAdvert,
  completedTasksList,
  isCompletedTasksListEmpty,
  onSetRuneStep,
  onActiveTaskChange,
  payPassMultiplier,
  onCheckCodeModal,
}) => {
  const getContent = () => {
    switch (activeTab) {
      case ETab.YOUTUBE:
        return <Youtube user={user} setClaimState={setClaimState} />;

      case ETab.TEA_FI:
        return <TeaFi user={user} setClaimState={setClaimState} />;

      case ETab.MINER_TYCOON:
        return <MinerTycoon user={user} setClaimState={setClaimState} />;

      case ETab.AZURE:
        return <AzureGamesCampaign user={user} setClaimState={setClaimState} />;

      case ETab.ACTIVE:
        return (
          <div className="task-wrapper">
            <div className="task-list-main-wrapper bebebe">
              <div className="task-list-wrapper">
                <TaskList
                  can_update_adsgram_ads={can_update_adsgram_ads}
                  can_update_onclicka_ads={can_update_onclicka_ads}
                  payPassMultiplier={payPassMultiplier}
                  walletTask={WALLET_TASK}
                  threeFriendsTask={THREE_FRIENDS_TASK}
                  taskList={activeTasksList}
                  onClaimTask={onClaimTask}
                  onChangeCurrentIndexTask={onChangeCurrentIndexTask}
                  readyToClaim={readyToClaim}
                  onSetShowVerifyModal={onSetShowVerifyModal}
                  onSetShowShareModal={onSetShowShareModal}
                  onShowAdgramsAdvert={onShowAdgramsAdvert}
                  onShowOnclickaAdvert={onShowOnclickaAdvert}
                  onCheckCodeModal={onCheckCodeModal}
                />
              </div>
            </div>
          </div>
        );

      case ETab.COMPLETED:
        return (
          <div className="task-wrapper">
            <div className="task-list-main-wrapper bebebe">
              <div className="task-list-wrapper">
                <TaskList
                  payPassMultiplier={payPassMultiplier}
                  walletTask={WALLET_TASK}
                  threeFriendsTask={THREE_FRIENDS_TASK}
                  taskList={completedTasksList}
                  onClaimTask={onClaimTask}
                  onChangeCurrentIndexTask={onChangeCurrentIndexTask}
                  readyToClaim={readyToClaim}
                  isEmpty={isCompletedTasksListEmpty}
                  user={user}
                  onSetRuneStep={onSetRuneStep}
                  isCompleted={activeTab === ETab.COMPLETED}
                />
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="content mates-content task-content">
      <TaskCounterAndDescription user={user} activeTab={activeTab} />
      <div className="task-root-wrapper">
        <ToggleGroup
          activeTab={activeTab}
          onChange={onActiveTaskChange}
          isCampaign={false}
        />
        {getContent()}
      </div>
    </div>
  );
};
