import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { TConditionsType } from "../../models";

const editCompletedIcon = require("../img/icon-completed.png") as string;
const editNotCompletedIcon = require("../img/icon-not-completed.png") as string;
const conditionsIcon = require("../img/conditions-icon.png") as string;

interface IProps {
  list: TConditionsType[];
  handleShowConditionsModal: (value: boolean) => void;
}

export const TopContent: React.FC<IProps> = ({
  list,
  handleShowConditionsModal,
}) => {
  return (
    <Stack flex="1" gap="30px" justifyContent="center">
      <Typography
        className="galaxy-color ab"
        textAlign="center"
        style={{
          fontSize: "31px",
        }}
      >
        Unlock Conditions
      </Typography>
      <Stack justifyContent="center" alignItems="center">
        <Box
          style={{
            display: "flex",
            alignItems: "flex-start",
            gap: "6px",
            flexDirection: "column",
            width: "280px",
          }}
        >
          {list.map((item) => (
            <Stack
              key={item.text}
              direction="row"
              alignItems="center"
              spacing={1}
              gap="8px"
            >
              <img
                width="29.61px"
                src={
                  item.isCompleted ? editCompletedIcon : editNotCompletedIcon
                }
                alt=""
              />
              <Typography
                display="flex"
                alignItems="center"
                style={{
                  fontSize: "20px",
                  color: item.isCompleted ? "#fff" : "#ffffff59",
                }}
              >
                {item.text}
                {item.conditionsModal ? (
                  <img
                    onClick={() => handleShowConditionsModal(true)}
                    width="20.3px"
                    height="20.3px"
                    src={conditionsIcon}
                    alt=""
                    style={{ marginLeft: "12px" }}
                  />
                ) : null}
              </Typography>
            </Stack>
          ))}
        </Box>
      </Stack>
    </Stack>
  );
};
