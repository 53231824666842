import React from "react";

import { Button, Stack, Typography } from "@mui/material";

import { CSSTransition } from "react-transition-group";

interface IProps {
  open: boolean;
  close: () => void;
}

export const InfoModal: React.FC<IProps> = ({ open, close }) => {
  return (
    <>
      {open && (
        <div
          className={`overlay app-modal-root ${open ? "blur" : ""}`}
          style={{ zIndex: "12" }}
        ></div>
      )}
      <CSSTransition in={open} timeout={500} classNames="fade" unmountOnExit>
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          onClick={(e) => e.stopPropagation()}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            zIndex: 13,
            height: "unset",
            borderRadius: "27px",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            padding: "24px",
          }}
        >
          <div className="app-modal-content task-modal-content">
            <div className="task-modal-desc">
              <Typography
                style={{
                  fontSize: "24px",
                  color: "#fff",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
                className="ab"
              >
                How to claim W-Coin NFT?
              </Typography>
              <Stack>
                <ul
                  style={{
                    margin: 0,
                    padding: 0,
                    fontSize: "20px",
                    color: "#fff",
                  }}
                >
                  <li>Connect your TON Wallet</li>
                  <li>Complete all conditions</li>
                  <li>Gas fee is required for minting</li>
                </ul>
              </Stack>
              <Button
                onClick={close}
                className="ab"
                style={{
                  width: "203px",
                  backgroundColor: "#fff",
                  color: "#000",
                  borderRadius: "20px",
                  marginTop: "16px",
                  height: "47px",
                  fontSize: "18px",
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
