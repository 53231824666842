import React from "react";

import { Button, Stack, Typography } from "@mui/material";
import { CSSTransition } from "react-transition-group";

import { ROUTES } from "../../routers";

const icon = require("./info-galaxy-icon.png") as string;

export const InfoModal: React.FC = () => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const token = localStorage.getItem("PAY_PASS_INFO_MODAL_4");

    if (!token) {
      setShow(true);
      localStorage.setItem("PAY_PASS_INFO_MODAL_4", "true");
    }
  }, []);

  return (
    <>
      {show && (
        <div
          className={`overlay app-modal-root ${show ? "blur" : ""}`}
          style={{ zIndex: "12" }}
        ></div>
      )}
      <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit>
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          onClick={(e) => e.stopPropagation()}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            zIndex: 13,
            height: "unset",
            borderRadius: "27px",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            padding: "24px",
          }}
        >
          <div className="app-modal-content task-modal-content">
            <img
              width="165px"
              src={icon}
              alt=""
              style={{
                marginBottom: "35px",
              }}
            />
            <div className="task-modal-desc">
              <Typography
                style={{
                  fontSize: "24px",
                  color: "#fff",
                  textAlign: "center",
                }}
                className="ab"
              >
                Last days of W-Galaxy
              </Typography>
              <Stack>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#CDCDCD",
                    textAlign: "center",
                  }}
                >
                  Last chance to get <b> W-Galaxy</b> Membership - access closes
                  in less than a week!
                </Typography>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#6D6D6D",
                    textAlign: "center",
                  }}
                >
                  P.S. Are you ready to claim your Premarket NFT?
                </Typography>
              </Stack>
              <Button
                onClick={() => {
                  setShow(false);
                  window.location.hash = ROUTES.PAY_PASS.PATH;
                }}
                className="ab"
                style={{
                  width: "100%",
                  backgroundColor: "#9747FF",
                  color: "#fff",
                  borderRadius: "20px",
                  marginTop: "16px",
                  height: "47px",
                  fontSize: "18px",
                }}
              >
                Take a chance
              </Button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
