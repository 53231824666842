import React from "react";

import { Stack } from "@mui/material";
import { BackButton } from "@vkruglikov/react-telegram-web-app";

import { TemplatePages } from "../template-pages";

import { usePageNftApi } from "./hooks";
import { Header } from "./header";
import { Heading } from "./heading";
import { Content } from "./content";
import { ROUTES } from "../routers";
import { ConditionsModal } from "./conditions-modal";

const bgIcon = require("./img/bg.jpg") as string;

export const PageNftAirdrop: React.FC = () => {
  const {
    isLoading,
    CONDITIONS_TASKS,
    handleConnectWallet,
    handleDisconnect,
    address,
    databaseWalletAddress,
    canShowConnectedBtn,
    nftAirdropData,
    isFetching,
    isEligible,
    showConditionsModal,
    handleShowConditionsModal,
  } = usePageNftApi();

  return (
    <TemplatePages isLoading={isLoading} isFetching={isFetching} bg={bgIcon}>
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      {showConditionsModal && (
        <div
          className={`overlay app-modal-root ${
            showConditionsModal ? "blur" : ""
          }`}
          style={{ zIndex: "12" }}
        ></div>
      )}
      <ConditionsModal
        show={showConditionsModal}
        onClose={() => handleShowConditionsModal(false)}
      />
      <Header />
      <Stack
        flexDirection="column"
        textAlign="center"
        height="calc(100% - 60px)"
        width="100%"
        justifyContent="flex-start"
        overflow="auto"
        style={{
          padding: "40px 24px 10px 24px",
        }}
      >
        <Heading isEligible={isEligible} />
        <Content
          isEligible={isEligible}
          list={CONDITIONS_TASKS}
          handleConnectWallet={handleConnectWallet}
          handleDisconnect={handleDisconnect}
          address={address}
          databaseWalletAddress={databaseWalletAddress}
          canShowConnectedBtn={canShowConnectedBtn}
          nftAirdropData={nftAirdropData}
          handleShowConditionsModal={handleShowConditionsModal}
        />
      </Stack>
    </TemplatePages>
  );
};
