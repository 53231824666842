import React from "react";

import { Oval } from "react-loader-spinner";

interface IProps {
  isLoading: boolean;
  label?: string;
  marginTop?: string;
  marginLeft?: string;
  top?: string;
}

export const Spinner: React.FC<IProps> = ({
  isLoading,
  label,
  marginTop,
  marginLeft,
  top,
}) => {
  return isLoading ? (
    <div
      className="app-spinner"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: label ? "blur(4px)" : undefined,
        position: "absolute",
        top: top ?? undefined,
        marginTop,
        marginLeft,
      }}
    >
      <Oval
        visible={isLoading}
        height="100"
        width="100"
        color="#9747FF"
        secondaryColor="rgb(225, 219, 239)"
        ariaLabel="oval-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      {label && (
        <p className="ab" style={{ color: "#fff", fontSize: "20px" }}>
          {label}
        </p>
      )}
    </div>
  ) : null;
};
