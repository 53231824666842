import { TagDescription } from "@reduxjs/toolkit/query";
import CryptoJS from "crypto-js";

import { EAPITagType } from "../../../enums";
import {
  baseApi,
  checkOrderBaseApi,
  externalBaseApi,
  rabbitBaseApi,
  JWT_TOKEN_KEY,
  legendBaseApi,
  okxCheckUidBaseApi,
} from "../../../services";
import { getClientTimestamp } from "../../../utils";
import { EWaiUpgradeType } from "../../wai/models";
import { ITask } from "../../page-tasks/type";

import {
  IAirdropResponse,
  IChestMe,
  IOpenOrderChestsOpen,
  IOpenOrderStarsChestsOpen,
  IUpdateChestsOpen,
  IUserPassiveIncomeResponse,
  TAuthArgs,
  TAuthResponse,
  TClaimStakingArgs,
  TClaimStakingResponse,
  TCreateStakeArgs,
  TCreateStakeResponse,
  TLeaderboard,
  TMates,
  TMatesGetRewardsResponse,
  TPayPassGenerateInvoice,
  TRabbit,
  TRegistrationApp,
  TStats,
  TUpdateClientArgs,
  TUser,
  TValidateUserInGroup,
  TValidateUserInGroupArgs,
} from "./models";
import { ILeaderboardApiResponse, TLeague } from "./wai.models";

localStorage.removeItem(JWT_TOKEN_KEY);

const getInitData = () => {
  return (
    // @ts-ignore
    process.env.REACT_APP_INIT_DATA_EXTERNAL_QUERY ?? window.Telegram.WebApp.initData
  );
};

const clientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    auth: builder.mutation<TAuthResponse, TAuthArgs>({
      query: (queryArg) => {
        // @ts-ignore
        return {
          url: `/api/auth/local`,
          method: "POST",
          body: {
            identifier:
              process.env.REACT_APP_DEVELOPMENT_ONLY_TEST_USER_EMAIL ??
              queryArg.username,
            password:
              process.env.REACT_APP_DEVELOPMENT_ONLY_TEST_USER_PASSWORD ??
              queryArg.password,
          },
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TAuthResponse) => {
        localStorage.setItem(JWT_TOKEN_KEY, response.jwt);
        return response;
      },
      // @ts-ignore
      invalidatesTags: [EAPITagType.USERS],
    }),
    registrationApp: builder.mutation<TAuthResponse, TRegistrationApp>({
      query: (queryArg) => {
        const hash = getInitData();

        return {
          url: `api/users-permissions/users/registration-app?${hash}`,
          method: "POST",
          body: queryArg,
        };
      },
      transformResponse: (response: TAuthResponse) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      invalidatesTags: [EAPITagType.NOTIFY_USERS],
    }),
    getTasks: builder.query<ITask[], void>({
      query: () => {
        return {
          url: "/api/tasks",
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: ITask[]) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      providesTags: [EAPITagType.TASKS],
    }),
    getLeaderboard: builder.query<TLeaderboard, void>({
      query: () => ({
        url: "/api/users-permissions/users/leaderboard",
        method: "GET",
      }),
      transformResponse: (response: TLeaderboard) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      keepUnusedDataFor: 5,
      providesTags: [EAPITagType.LEADERBOARD],
    }),
    getValidateUserInGroup: builder.query<
      TValidateUserInGroup,
      TValidateUserInGroupArgs
    >({
      query: (queryArg) => {
        return {
          url: `/api/users-permissions/users/validate-user-in-group/${queryArg.resource}`,
          method: "GET",
        };
      },
      transformResponse: (response: TValidateUserInGroup) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      keepUnusedDataFor: 5,
      providesTags: [EAPITagType.VALIDATE_USER_IN_GROUP],
    }),
    getStats: builder.query<TStats[], void>({
      query: () => {
        return {
          url: "/api/stat",
          method: "GET",
        };
      },
      extraOptions: { maxRetries: 3 },
      keepUnusedDataFor: 5,
      providesTags: [EAPITagType.STATS],
    }),
    getMates: builder.query<TMates, void>({
      query: () => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/get-mates?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: TMates) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      providesTags: [EAPITagType.MATES_LIST],
    }),
    getMatesGetRewards: builder.query<TMatesGetRewardsResponse, void>({
      query: () => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/claim-mates-reward?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: TMatesGetRewardsResponse) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      providesTags: [EAPITagType.MATES_GET_REWARDS],
    }),
    createStake: builder.mutation<TCreateStakeResponse, TCreateStakeArgs>({
      query: (queryArg) => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/stake/create?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "POST",
          body: {
            days: queryArg.days,
            stake_percent_from_balance: queryArg.stake_percent_from_balance,
          },
        };
      },
      transformResponse: (response: TCreateStakeResponse) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      invalidatesTags: [EAPITagType.STAKE_CREATE],
    }),
    claimStaking: builder.mutation<TClaimStakingResponse, TClaimStakingArgs>({
      query: (queryArg) => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/stake/claim?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "POST",
          body: {
            timestamp: queryArg.timestamp,
          },
        };
      },
      transformResponse: (response: TClaimStakingResponse) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      invalidatesTags: [EAPITagType.CLAIM_STAKING],
    }),
    getWai: builder.query<IUserPassiveIncomeResponse, void>({
      query: () => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();

        return {
          url: `/api/users-permissions/passive-income/me?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.USER_PASSIVE_INCOME],
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: IUserPassiveIncomeResponse) => {
        return response;
      },
      providesTags: [EAPITagType.USERS],
    }),
    getWaiClaim: builder.query<IUserPassiveIncomeResponse, void>({
      query: (queryArg) => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();

        return {
          url: `/api/users-permissions/passive-income/claim?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.USER_PASSIVE_INCOME_CLAIM],
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: IUserPassiveIncomeResponse) => {
        return response;
      },
      // @ts-ignore
      providesTags: [EAPITagType.USERS],
    }),
    getWaiIncreaseLevel: builder.query<
      IUserPassiveIncomeResponse,
      { type: EWaiUpgradeType }
    >({
      query: (queryArg) => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();

        return {
          url: `/api/users-permissions/passive-income/increase-level/${queryArg.type}?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.USER_PASSIVE_INCOME_INCREASE_LEVEL],
        };
      },
      transformResponse: (response: IUserPassiveIncomeResponse) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      providesTags: [EAPITagType.USERS],
    }),
    getWaiLeaderboardByLeague: builder.query<
      ILeaderboardApiResponse,
      { league: TLeague }
    >({
      query: (queryArg) => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();
        return {
          url: `/api/users-permissions/passive-income/leaderboard/${queryArg.league}?timestamp=${clientTimestamp}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.WAI_LEADERBOARD],
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: ILeaderboardApiResponse) => {
        return response;
      },
      providesTags: [EAPITagType.USERS],
    }),
  }),
});

export const {
  useAuthMutation,
  useRegistrationAppMutation,
  useGetTasksQuery,
  useGetStatsQuery,
  useGetLeaderboardQuery,
  useLazyGetValidateUserInGroupQuery,
  useLazyGetMatesGetRewardsQuery,
  useGetMatesQuery,
  useCreateStakeMutation,
  useClaimStakingMutation,
  useGetWaiQuery,
  useLazyGetWaiClaimQuery,
  useLazyGetWaiIncreaseLevelQuery,
  useLazyGetWaiLeaderboardByLeagueQuery,
} = clientApi;

type TVerifyTaskArgs = {
  telegramUser: string;
  taskName: string;
};

type TVerifyTaskResponse = {
  status: "success" | "fail" | "error";
  message: string;
  telegramUser: string;
  taskName: string;
  isFinished: boolean;
};

const legendApi = legendBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyTask: builder.mutation<TVerifyTaskResponse, TVerifyTaskArgs>({
      query: ({ telegramUser, taskName }) => ({
        url: "/verifyTask",
        method: "POST",
        body: { telegramUser, taskName },
        credentials: "omit",
      }),
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TVerifyTaskResponse) => {
        return response;
      },
      // @ts-ignore
      invalidatesTags: [EAPITagType.LEGENDS],
    }),
  }),
});

export const { useVerifyTaskMutation } = legendApi;

const checkOrderApi = checkOrderBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    checkUpdateKeyOrder: builder.mutation<{status: 'success' | 'cancel'}, { order_id?: string, status: string, type:'ton' | 'tg-stars',  transaction_id?: string}>({
      query: ({ order_id,  status, type, transaction_id}) => {
        return {
          url: `/api/external-api/order/${type}/update`,
          method: "POST",
          body: { order_id,  status, transaction_id},
        }
      },
      extraOptions: { maxRetries: 0 },
      invalidatesTags: [EAPITagType.CHECK_ORDER as TagDescription<EAPITagType.CHECK_ORDER_API>, EAPITagType.CHESTS as TagDescription<EAPITagType.CHECK_ORDER_API>],
      transformResponse: (response: {status: 'success'}) => {
        return response;
      },
    }),
  }),
});

export const {
  useCheckUpdateKeyOrderMutation,
} = checkOrderApi;

const externalApi = externalBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateRedeemCode: builder.mutation<boolean, { code: string }>({
      query: ({ code }) => ({
        url: `/api/integrations/redeem-code`,
        method: "POST",
        body: { code },
      }),
      transformResponse: (response: boolean) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      // @ts-ignore
      invalidatesTags: [EAPITagType.EXTERNAL_API],
    }),
    updateUserExternalApi: builder.mutation<
      TUser,
      {
        encryptedData: string;
        encryptedSymmetricKey: string;
        iv: string;
        authTag: string;
        data: TUpdateClientArgs;
      }
    >({
      query: ({ data, encryptedData, encryptedSymmetricKey, iv, authTag }) => {
        return {
          url: "/api/external-api/user/update",
          method: "POST",
          body: { encryptedData, data, encryptedSymmetricKey, iv, authTag },
        };
      },
      extraOptions: { maxRetries: 3 },
      invalidatesTags: [
        EAPITagType.EXTERNAL_API_UPDATE_USER as TagDescription<EAPITagType.EXTERNAL_API>,
      ],
    }),
    getPayPassGenerateInvoice: builder.mutation<TPayPassGenerateInvoice, void>({
      query: () => {
        return {
          url: "/api/external-api/pay-pass/generate-invoice",
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    getPayPassLiteGenerateInvoice: builder.mutation<TPayPassGenerateInvoice, void>({
      query: () => {
        return {
          url: "/api/external-api/pay-pass-lite/generate-invoice",
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    getSkinsGenerateInvoice: builder.mutation<TPayPassGenerateInvoice, void>({
      query: () => {
        return {
          url: "/api/external-api/skin/generate-invoice",
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    getSkinsGenerate2Invoice: builder.mutation<TPayPassGenerateInvoice, void>({
      query: () => {
        return {
          url: "/api/external-api/skin-2/generate-invoice",
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    getSkinsGenerate3Invoice: builder.mutation<TPayPassGenerateInvoice, void>({
      query: () => {
        return {
          url: "/api/external-api/skin-3/generate-invoice",
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    getSkinsGenerate4Invoice: builder.mutation<TPayPassGenerateInvoice, void>({
      query: () => {
        return {
          url: "/api/external-api/skin-4/generate-invoice",
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    getRecoveryTappingStarsPriceInvoice: builder.mutation<TPayPassGenerateInvoice, void>({
      query: () => {
        return {
          url: "/api/external-api/extra-tapping/generate-invoice",
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    getWaiBonusInvoice: builder.mutation<TPayPassGenerateInvoice, void>({
      query: () => {
        return {
          url: "/api/external-api/wai-bonus/generate-invoice",
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    getExternalChestsMe: builder.query<IChestMe, void>({
      query: () => ({
        url: `/api/external-api/chests/me`,
        method: "GET",
      }),
      keepUnusedDataFor: 5,
      transformResponse: (response: IChestMe) => {
        return response;
      },
      extraOptions: { maxRetries: 3 },
      providesTags: [EAPITagType.CHESTS as TagDescription<EAPITagType.EXTERNAL_API>],
    }),
    // CHESTS start
    updateChestsOpen: builder.mutation<IUpdateChestsOpen, { type: string}>({
      query: ({ type }) => {
        return {
          url: `/api/external-api/chests/open`,
          method: "POST",
          body: { type},
        };
      },
      invalidatesTags: [EAPITagType.CHESTS as TagDescription<EAPITagType.EXTERNAL_API>, EAPITagType.USERS as TagDescription<EAPITagType.EXTERNAL_API>],
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: IUpdateChestsOpen) => {
        return response;
      },
    }),
    updateKeyOpenOrder: builder.mutation<IOpenOrderChestsOpen, { product_id: string, amount: number}>({
      query: ({ product_id,  amount}) => {
        return {
          url: `/api/external-api/order/ton/open`,
          method: "POST",
          body: { product_id, amount},
        };
      },
      invalidatesTags: [EAPITagType.OPEN_ORDER as TagDescription<EAPITagType.EXTERNAL_API>, EAPITagType.CHESTS as TagDescription<EAPITagType.EXTERNAL_API>],
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: IOpenOrderChestsOpen) => {
        return response;
      },
    }),
    updateKeyOrder: builder.mutation<{status: 'success' | 'cancel'}, { order_id?: string, status: string, type:'ton' | 'tg-stars',  transaction_id?: string}>({
      query: ({ order_id,  status, type, transaction_id}) => {
        return {
          url: `/api/external-api/order/${type}/update`,
          method: "POST",
          body: { order_id,  status, transaction_id},
        }
      },
      invalidatesTags: [EAPITagType.CLOSE_ORDER as TagDescription<EAPITagType.EXTERNAL_API>, EAPITagType.CHESTS as TagDescription<EAPITagType.EXTERNAL_API>],
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: {status: 'success'}) => {
        return response;
      },
    }),
    updateKeyStarsOpenOrder: builder.mutation<IOpenOrderStarsChestsOpen, { product_id: string}>({
      query: ({ product_id}) => {
        return {
          url: `api/external-api/order/tg-stars/open`,
          method: "POST",
          body: { product_id},
        };
      },
      invalidatesTags: [EAPITagType.OPEN_STARS_ORDER as TagDescription<EAPITagType.EXTERNAL_API>, EAPITagType.CHESTS as TagDescription<EAPITagType.EXTERNAL_API>],
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: IOpenOrderStarsChestsOpen) => {
        return response;
      },
    }),
    // CHEST END
    getCheckUserActivity: builder.query<any, void>({
      query: () => {
        return {
          url: "/api/external-api/check-user-activity",
          method: "GET",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    updateGetCheckJetTonTaskExternalApi: builder.mutation<void, void>({
      query: () => {
        return {
          url: `/api/external-api/verify-jetton-2`,
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    updateGetCheckTrumpEmpireTaskExternalApi: builder.mutation<void, void>({
      query: () => {
        return {
          url: `/api/external-api/verify-minertycoon`,
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    updateVerifyTeafi: builder.mutation<{
      isMember: boolean;
      fromPartner: boolean;
      hasSwaps: boolean
    }, void>({
      query: () => {
        return {
          url: "/api/external-api/verify-teafi",
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: {
        isMember: boolean;
        fromPartner: boolean;
        hasSwaps: boolean
      }) => {
        return response;
      },
    }),
    updatePayDonateInvoice: builder.mutation<TPayPassGenerateInvoice, {amount: number}>({
      query: ({amount}) => {
        return {
          url: `/api/external-api/donate-developers/generate-invoice/${amount}`,
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    updateStyleInvoice: builder.mutation<TPayPassGenerateInvoice, {amount: number}>({
      query: ({amount}) => {
        return {
          url: `/api/external-api/custom-style/generate-invoice/${amount}`,
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    getNftAirdropStatus: builder.query<IAirdropResponse, void>({
      query: () => {
        return {
          url: "/api/external-api/get-nft-airdrop-status",
          method: "GET",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: IAirdropResponse) => {
        return response;
      },
      providesTags: [
        EAPITagType.EXTERNAL_AIRDROP_STATUS as TagDescription<EAPITagType.EXTERNAL_API>,
      ],
    }),
    updateNftAirdropWalletAddress: builder.mutation<void, {
      wallet_address: string
    }>({
      query: ({wallet_address}) => {
        return {
          url: `api/external-api/add-nft-airdrop-wallet-address`,
          method: "POST",
          body: {wallet_address},
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: void) => {
        return response;
      },
      invalidatesTags: [
        EAPITagType.EXTERNAL_AIRDROP_STATUS as TagDescription<EAPITagType.EXTERNAL_API>,
      ],
    }),
    updateMintNftAirdrop: builder.mutation<{
      data: {
        status: "success",
        mint_address: string;
        error: {
          message: string;
        }
      }
    }, {
      transaction: string;
    }>({
      query: ({transaction}) => {
        return {
          url: "/api/external-api/mint-nft-airdrop",
          method: "POST",
          body: {
            transaction,
          }
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: {
        data: {
          status: "success",
          mint_address: string;
          error: {
            message: string;
          }
        }
      }) => {
        return response;
      },
      invalidatesTags: [
        EAPITagType.EXTERNAL_AIRDROP_STATUS as TagDescription<EAPITagType.EXTERNAL_API>,
      ],
    }),
    updateGetVerifyBurgerBlastApi: builder.mutation<void, void>({
      query: () => {
        return {
          url: `api/external-api/verify-burgerblast`,
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    updateGetVerifyChainGptApi: builder.mutation<void, void>({
      query: () => {
        return {
          url: `api/external-api/verify-chaintgi`,
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    updateGetNothingApi: builder.mutation<void, void>({
      query: () => {
        return {
          url: `api/external-api/verify-nothing`,
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    updateGetRokoApi: builder.mutation<void, void>({
      query: () => {
        return {
          url: `api/external-api/verify-roko`,
          method: "POST",
        };
      },
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const {
  useUpdateRedeemCodeMutation,
  useUpdateUserExternalApiMutation,
  useUpdateGetCheckJetTonTaskExternalApiMutation,
  useUpdateGetCheckTrumpEmpireTaskExternalApiMutation,
  useGetPayPassGenerateInvoiceMutation,
  useGetPayPassLiteGenerateInvoiceMutation,
  useGetExternalChestsMeQuery,
  useUpdateChestsOpenMutation,
  useGetCheckUserActivityQuery,
  useUpdateKeyOpenOrderMutation,
  useUpdateKeyOrderMutation,
  useUpdateKeyStarsOpenOrderMutation,
  useGetSkinsGenerateInvoiceMutation,
  useGetSkinsGenerate2InvoiceMutation,
  useGetSkinsGenerate3InvoiceMutation,
  useGetSkinsGenerate4InvoiceMutation,
  useGetRecoveryTappingStarsPriceInvoiceMutation,
  useGetWaiBonusInvoiceMutation,
  useUpdateVerifyTeafiMutation,
  useUpdatePayDonateInvoiceMutation,
  useUpdateStyleInvoiceMutation,
  useGetNftAirdropStatusQuery,
  useUpdateNftAirdropWalletAddressMutation,
  useUpdateGetVerifyBurgerBlastApiMutation,
  useUpdateGetVerifyChainGptApiMutation,
  useUpdateMintNftAirdropMutation,
  useUpdateGetNothingApiMutation,
  useUpdateGetRokoApiMutation,
} = externalApi;

const okxCheckUidApi = okxCheckUidBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCheckUid: builder.query<any, { uid: string }>({
      query: ({ uid }) => {
        const generateSignature = (
          method: string,
          requestPath: string,
          secret: string
        ) => {
          const timestamp = new Date().toISOString();
          const prehashString = `${timestamp}${method}${requestPath}`;
          const signature = CryptoJS.HmacSHA256(prehashString, secret).toString(
            CryptoJS.enc.Base64
          );
          return { signature, timestamp };
        };

        const method = "GET";
        const requestPath = `/api/v5/affiliate/invitee/detail?uid=${uid}`;
        const { signature, timestamp } = generateSignature(
          method,
          requestPath,
          "FD5A3A96B7D72897B0B74BC953B608DE"
        );

        return {
          url: requestPath,
          method,
          headers: {
            "OK-ACCESS-KEY": "9acf5a7a-052f-4183-b29d-b14b4ad6da45",
            "OK-ACCESS-SIGN": signature,
            "OK-ACCESS-PASSPHRASE": "W-coin20244",
            "OK-ACCESS-TIMESTAMP": timestamp,
          },
        };
      },
      keepUnusedDataFor: 5,
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: any[]) => response,
      providesTags: [EAPITagType.OKX_CHECK_UID],
    }),
  }),
});

export const { useLazyGetCheckUidQuery } = okxCheckUidApi;

// @ts-ignore
const userId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id || '';

const rabbitApi = rabbitBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVerifyRabbitTask: builder.query<TRabbit, void>({
      query: () => ({
        url: `/uEgU3shj4FDvqZcuO9Lq1KDTf3GZyGBT/${userId}`,
        method: "GET",
        // credentials: "omit",
      }),
      extraOptions: { maxRetries: 3 },
      transformResponse: (response: TRabbit) => {
        return response;
      },
    }),
  }),
});

export const { useLazyGetVerifyRabbitTaskQuery } = rabbitApi;
