import React from "react";

import { ETask, ITask, TGroupTask } from "../type";
import { ROUTES } from "../../routers";
import { TaskItem } from "../task-item";
import { useNavigate } from "react-router-dom";
import { TAppUser } from "../../contexts/types";
import { ERune, TRune } from "../../hooks/rune/models";
import { ADSGRAM_FIELD, ON_CLICKA_FIELD } from "../consts";
import { AdsgramTasks } from "../campaigns/adsgram-tasks";
import { useShowContentApi } from "../../../hooks";

const oIcon =
  require("../../../components/hooks/rune/img/o-icon.png") as string;

interface IProps {
  can_update_adsgram_ads?: number;
  can_update_onclicka_ads?: number;
  payPassMultiplier?: number;
  walletTask: ITask;
  threeFriendsTask: ITask;
  taskList: TGroupTask;
  onClaimTask: (ct: string | null) => Promise<void>;
  onChangeCurrentIndexTask: (ct: string | null) => void;
  readyToClaim: {};
  isEmpty?: boolean;
  user?: TAppUser;
  onSetRuneStep?: (runeStep: TRune) => void;
  isCompleted?: boolean;
  onSetShowVerifyModal?: (value: boolean) => void;
  onSetShowShareModal?: () => void;
  onShowAdgramsAdvert?: () => void;
  onShowOnclickaAdvert?: () => void;
  onCheckCodeModal?: (value: boolean) => void;
}

export const TaskList: React.FC<IProps> = ({
  threeFriendsTask,
  walletTask,
  taskList,
  onClaimTask,
  onChangeCurrentIndexTask,
  onCheckCodeModal,
  readyToClaim,
  isEmpty,
  user,
  onSetRuneStep,
  isCompleted,
  onSetShowVerifyModal,
  onSetShowShareModal,
  onShowAdgramsAdvert,
  onShowOnclickaAdvert,
  payPassMultiplier,
  can_update_adsgram_ads,
  can_update_onclicka_ads,
}) => {
  const navigate = useNavigate();

  const openPage = (path: string, state?: any) => {
    navigate(path, state);
  };

  // @ts-ignore
  const runeStep = user?.claim_state?.tasks?.rune?.step === ERune.C;
  const { canShowContent } = useShowContentApi();

  return (
    <div
      className={`new-task-group-wrapper ${isEmpty && "task-list-empty"}`}
      style={{ position: "relative" }}
    >
      {runeStep && isCompleted && (
        <img
          src={oIcon}
          onClick={() =>
            onSetRuneStep?.({
              step: ERune.O,
              completed: false,
            })
          }
          alt=""
          className="c-rune-icon c-rune-icon-task"
        />
      )}
      {isEmpty ? (
        <p className="empty-list-description">
          The list of completed tasks is empty
        </p>
      ) : (
        Object.keys(taskList)
          .sort((a, b) => {
            if (a === "Specials") return -1;
            if (b === "Specials") return 1;
            return a.localeCompare(b);
          })
          .map((category) => {
            if (taskList[category]?.length) {
              return (
                <>
                  <div
                    key={category}
                    className="plain-text task-list-header-text green-color new-task-group-wrapper-label"
                    style={{ fontWeight: 600 }}
                  >
                    {category}
                  </div>
                  {/* {category === "Web3" && canShowContent ? (
                    <AdsgramTasks />
                  ) : null} */}
                  {taskList[category].map((task, index) => {
                    const isAdaGramDisabledItem = !!(
                      task.type === ADSGRAM_FIELD && can_update_adsgram_ads
                    );

                    const isOncklicaDisabledItem = !!(
                      task.type === ON_CLICKA_FIELD && can_update_onclicka_ads
                    );

                    if (task.type === ETask.INVITE_THREE_MATE) {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            openPage(ROUTES.MATES.PATH, {
                              state: { inviteThreeMatesData: true },
                            })
                          }
                          style={
                            task.is_done
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : undefined
                          }
                        >
                          <TaskItem
                            key={index}
                            payPassMultiplier={payPassMultiplier}
                            {...threeFriendsTask}
                          />
                        </div>
                      );
                    } else if (task.type === ETask.CONNECT_WALLET) {
                      return (
                        <div
                          key={index}
                          onClick={() => openPage(ROUTES.WALLET.PATH)}
                          style={
                            task.is_done
                              ? {
                                  pointerEvents: "none",
                                  cursor: "not-allowed",
                                }
                              : undefined
                          }
                        >
                          <TaskItem
                            key={index}
                            payPassMultiplier={payPassMultiplier}
                            {...walletTask}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <TaskItem
                          key={index}
                          onCheckCodeModal={() => {
                            onChangeCurrentIndexTask(task.type);
                            onCheckCodeModal?.(true);
                          }}
                          isAdaGramDisabledItem={isAdaGramDisabledItem}
                          isOncklicaDisabledItem={isOncklicaDisabledItem}
                          payPassMultiplier={payPassMultiplier}
                          // @ts-ignore
                          canClaim={readyToClaim[task.type] || false}
                          {...task}
                          onClaim={() => onClaimTask(task.type)}
                          onOpenModal={() => {
                            if (!task.isAdverts) {
                              onChangeCurrentIndexTask(task.type);
                            }
                          }}
                          onSetShowVerifyModal={() => {
                            onChangeCurrentIndexTask(task.type);
                            onSetShowVerifyModal?.(true);
                          }}
                          onSetShowShareModal={() => {
                            onChangeCurrentIndexTask(task.type);
                            onSetShowShareModal?.();
                          }}
                          onShowAdgramsAdvert={() => {
                            onShowAdgramsAdvert?.();
                          }}
                          onShowOnclickaAdvert={() => {
                            onShowOnclickaAdvert?.();
                          }}
                        />
                      );
                    }
                  })}
                </>
              );
            } else {
              return null;
            }
          })
      )}
    </div>
  );
};
